import React, { useRef, useState, useEffect } from 'react'
import userApi from 'src/utils/axios';
import { Formik } from "formik";
import * as yup from 'yup';
import ConfigData from './../../../../config/constant.json'
import {
    CCard, CCardBody, CCol, CRow, CCardText, CCardTitle,
    CModalHeader, CModal, CModalFooter, CFormTextarea, CFormSelect, CInputGroup,
    CModalTitle, CModalBody, CSpinner, CFormLabel,
    CForm, CFormInput,
} from '@coreui/react'
import CropEasy from '../../crop/CropEasy';
import { dataURLtoFile } from 'src/utils/dataURLtoFile';
import Select from 'react-select';


const EditCourse = (props) => {
    let fileData;
    const [loader, setLoader] = React.useState(false);
    const [openCrop, setOpenCrop] = useState(false);
    const [image, setImage] = useState(null);
    const [updatedImage, setUpdatedImage] = useState(null)
    const [originalName, setOriginalName] = useState(props.course.originalName);
    const [imageError, setImageError] = useState(false);
    let url1 = `${ConfigData.SERVER_URL}/courseImages/${props.course.fileName}.${props.course.extension}`;
    const [accountData, setAccountData] = useState([]);

    const addCourseSchema = yup.object({

        courseName: yup.string()
            .required('Course-Name is required').min(3),

        // price: yup.string()
        //     .required('Price is required'),

        sub_acc_id: yup.string()
            .required('Payment Settlement Account is required'),

        description: yup.string()
            .required('Description is required'),

        qualificationEligibility: yup.string()
            .required('Qualification Eligibility is required'),

    });

    let _id = props.course._id

    const initialValues = {
        fileName: props.course.fileName,
        courseName: props.course.courseName,
        // price: props.course.price,
        description: props.course.description,
        offeredBy: props.course.offeredBy,
        qualificationEligibility: props.course.qualificationEligibility,
        sub_acc_id: props.course.sub_acc_id
    }

    const toDataURL = (url) => fetch(url)
        .then(response => response.blob())
        .then(blob => new Promise((resolve, reject) => {
            const reader = new FileReader()
            reader.onloadend = () => resolve(reader.result);
            reader.onerror = reject;
            reader.readAsDataURL(blob);
        }))

    useEffect(() => {
        getCourseData();
    }, [])

    const getCourseData = async () => {
        const response = await userApi.get(`${ConfigData.SERVER_URL}/admin/course/getSubAccountId`);

        const optionsData = response.data.data.map(user => ({
            value: user.sub_acc_id,
            label: `(${user.sub_acc_id}) account ending with ${user.stl_bank_account_no}`
        }));

        setAccountData(optionsData);
    }

    React.useEffect(() => {
        toDataURL(url1)
            .then(dataUrl => {
                fileData = dataURLtoFile(dataUrl, originalName);
                setUpdatedImage(fileData);
            })
    }, [])

    const handleEditCourse = async (values, submitProps) => {
        try {
            if (updatedImage == null) {
                setImageError(true);
                return;
            }
            let formData = new FormData();
            formData.append("fileName", updatedImage ? updatedImage : fileData);
            formData.append("courseName", values.courseName);
            formData.append("sub_acc_id", values.sub_acc_id);
            // formData.append("price", values.price);
            formData.append("qualificationEligibility", values.qualificationEligibility);
            formData.append("description", values.description);
            formData.append("_id", props.course._id);

            setLoader(true)
            const response = await userApi.post(`${ConfigData.SERVER_URL}/admin/course/editcourse`, formData)
            setLoader(false)
            if (response.data.status == false) {
                throw Error(response.data.message)
            }
            let toast = {
                "status": true,
                "body": "Course Edited",
                "message": "Success"
            }
            props.addToaster(toast)
            props.editCourseCallback(response.data.data)
        } catch (e) {
            let toast = {
                "status": false,
                "body": e.message,
                "message": "Failed"
            }
            props.addToaster(toast)
        }
    }

    const handleChange = (e) => {
        const file = e.target.files[0];
        setOriginalName(file.name);
        if (file) {
            const reader = new FileReader();
            reader.readAsDataURL(e.target.files[0]);
            reader.addEventListener('load', () => {
                setImage(reader.result);
            })
            setOpenCrop(true);
        }
    }

    const cropImageFile = (updatedimg) => {
        setUpdatedImage(updatedimg);
        setImageError(false);
    }

    return (
        <>

            <CModal visible={props.visible} alignment="center" onClose={props.onClose} backdrop='static'>
                <CModalHeader onClose={props.onClose}>
                    <CModalTitle>Edit Course</CModalTitle>
                </CModalHeader>
                <CModalBody>
                    <>
                        <CCardBody>
                            <Formik
                                initialValues={initialValues}
                                validationSchema={addCourseSchema}
                                onSubmit={(values, actions) => {
                                    actions.setFieldValue('fileName', '');
                                    handleEditCourse(values)
                                }}
                            >
                                {(formikProps) => (
                                    <CForm>
                                        {!openCrop ? (<div className="mb-3">
                                            <CFormLabel htmlFor="formFile">Select Image</CFormLabel>
                                            <CFormInput style={{ display: `${updatedImage != null ? 'none' : ''}` }}
                                                onChange={(event) => handleChange(event)}
                                                accept="image/*"
                                                type="file" id="formFile" />
                                            {updatedImage != null ? <div><img src={image ? image : `${ConfigData.SERVER_URL}/courseImages/${props.course.fileName}.${props.course.extension}`} style={{ width: 100, height: 90 }} />{" " + originalName + " "}<button onClick={() => setUpdatedImage(null)} className="btn btn-outline-primary">Clear</button></div> : ''}
                                            {imageError && <div style={{ color: 'red' }}>Please select image</div>}
                                        </div>) : (<CropEasy  {...{ setOpenCrop, cropImageFile, image, setImage, originalName }} />)}
                                        {/* <CInputGroup className="mb-3"> */}
                                        <label className='text-sm'>Course Name</label>
                                        <CFormInput
                                            placeholder="Course Name"
                                            autoComplete="courseName"
                                            onChange={formikProps.handleChange('courseName')}
                                            value={formikProps.values.courseName}
                                            onBlur={formikProps.handleBlur('courseName')}
                                        />
                                        {/* </CInputGroup> */}
                                        <p style={{ color: 'red' }}>{formikProps.touched.courseName && formikProps.errors.courseName}</p>

                                        <CCol md={12}>
                                            <label className='text-sm'>Payment Settlement Account to :</label>
                                            <Select
                                                className="basic-single"
                                                classNamePrefix="select"
                                                isClearable={true}
                                                onChange={(selectedOption) => formikProps.setFieldValue('sub_acc_id', selectedOption ? selectedOption.value : '')}
                                                value={accountData.find(option => option.value === formikProps.values.sub_acc_id) || null}
                                                isSearchable={true}
                                                options={accountData}
                                            />

                                            <p style={{ color: 'red' }}>{formikProps.touched.sub_acc_id && formikProps.errors.sub_acc_id}</p>
                                        </CCol>
                                        <label className='text-sm'>Qualification Eligibility:</label>
                                        <CFormSelect aria-label="Default select example"
                                            onChange={formikProps.handleChange('qualificationEligibility')}
                                            value={formikProps.values.qualificationEligibility}>
                                            <option value='' disabled="true">Select Qualification</option>
                                            <option value="Anyone">Anyone</option>
                                            <option value="10th and above">10th and above</option>
                                            <option value="12th and above">12th and above</option>
                                            <option value="Graduation and above">Graduation and above</option>
                                        </CFormSelect>
                                        <p style={{ color: 'red' }}>{formikProps.touched.qualificationEligibility && formikProps.errors.qualificationEligibility}</p>
                                        {/* <CInputGroup className="mb-3"> */}
                                        {/* <label className='text-sm'>Price</label>

                                            <CFormInput
                                                placeholder="Price"
                                                autoComplete="price"
                                                onChange={formikProps.handleChange('price')}
                                                value={formikProps.values.price}
                                                onBlur={formikProps.handleBlur('price')}
                                            /> */}
                                        {/* </CInputGroup> */}
                                        <p style={{ color: 'red' }}>{formikProps.touched.price && formikProps.errors.price}</p>

                                        {/* <CInputGroup className="mb-3"> */}
                                        <label className='text-sm'>Course Description</label>

                                        <CFormTextarea
                                            id="description"
                                            rows="3"
                                            placeholder='Description'
                                            text="Must be 8-20 words long."
                                            onChange={formikProps.handleChange('description')}
                                            value={formikProps.values.description}
                                            onBlur={formikProps.handleBlur('description')}
                                        ></CFormTextarea>
                                        {/* </CInputGroup> */}
                                        <p style={{ color: 'red' }}>{formikProps.touched.description && formikProps.errors.description}</p>

                                        <CRow >
                                            <CCol xs={5}> </CCol>
                                            {loader && <CCol xs={2}><button onClick={formikProps.handleSubmit} type="submit" className="btn btn-outline-primary"><CSpinner component="span" size="sm" aria-hidden="true" />Submit</button> </CCol>}
                                            {!loader && <CCol xs={2}><button onClick={formikProps.handleSubmit} type="submit" className="btn btn-outline-primary">Submit</button> </CCol>}

                                            <CCol xs={5}> </CCol>

                                        </CRow>
                                    </CForm>
                                )}
                            </Formik>
                        </CCardBody>
                    </>
                </CModalBody>
            </CModal>
        </>
    );
}

export default EditCourse;