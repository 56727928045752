import React from 'react';
import { Formik } from "formik";
import * as yup from 'yup';
import { CForm, CCard, CRow, CCol, CFormLabel, CFormSelect, CFormInput, CButton, CCardBody, CCardTitle } from '@coreui/react/dist/index.js';
import userApi from 'src/utils/axios'
import ConfigData from 'src/config/constant.json'
import Toaster from 'src/views/shared/Toaster';
import { useHistory } from 'react-router-dom';



const AddCoupon = () => {

    const [lotNameInput, setLotNameInput] = React.useState(false);
    const [selectedFile, setSelectedFile] = React.useState(null);
    const [lotOptions, setLotOptions] = React.useState(null);
    const [courseData, setCourseData] = React.useState([]);
    const [toast, addToast] = React.useState(false)
    const history = useHistory();
    const formRef = React.useRef(null);

    React.useEffect(async () => {
        await fetchLotName();
        await getAllCourses();
    }, []);


    const moduleSchema = yup.object({
        couponType: yup.string().required('Coupon Type is required'),
        lotName: yup.string().required('Lot Name is required'),
        courses: yup.string().required('Courses Name is required'),
        startDate: yup.date().required('Start Date is required'),
        endDate: yup.date().required('End Date is required'),
        couponCode: yup.string().required('Coupon Code is required'),
        amount: yup.number().required('Amount is required'),
        newLotName: lotNameInput === true ? yup.string().required('Lot Name is required') : '',
    });

    //handle selected excel file
    const handleFileChange = (event) => {
        setSelectedFile(event.target.files[0]);
    };

    let addToaster = (toast) => {
        addToast(toast)
        setTimeout(() => {
            addToast(false)
        }, 3000)
    }

    //set lot name based on selected lot name
    const handleSetLotName = async (event) => {
        if (event.target.value === 'others') {
            setLotNameInput(true);
        } else {
            setLotNameInput(false);
        }
    }

    //fetch all lot name 
    const fetchLotName = async () => {
        try {
            const response = await userApi.get(`${ConfigData.SERVER_URL}/admin/coupons/getLotName`);
            if (response.data.data) {
                setLotOptions(response.data.data);
            }
        } catch (error) {
            console.error('Error fetching users:', error);
        }
    }

    // download excel file format to upload
    const handleDownloadExcel = async () => {
        try {
            const response = await userApi.get(`${ConfigData.SERVER_URL}/admin/coupons/downloadExcelFile`, {
                responseType: 'blob',
            })
            const blob = new Blob([response?.data], { type: 'application/octet-stream' });
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = 'downloaded_file.xlsx';
            a.style.display = 'none';
            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(url);
        } catch (error) {
            console.error('Error downloading Excel file:', error);
        }
    }

    //upload data from excel file
    const handleUploadExcel = async () => {
        try {
            const formData = new FormData();
            formData.append('file', selectedFile);
            const response = await userApi.post(`${ConfigData.SERVER_URL}/admin/coupons/uploadExcelData`, formData);
            if (response.data.status == true) {
                let toast = {
                    "status": true,
                    "message": response.data.message
                }
                addToaster(toast);
            } else {
                let toast = {
                    "status": false,
                    "message": response.data.message
                }
                addToaster(toast);
            }
        } catch (error) {
            console.error('Error uploading excel file:', error);
        }
    }

    // Function to fetch all courses
    const getAllCourses = async () => {
        try {
            const response = await userApi.get(`${ConfigData.SERVER_URL}/admin/course/getcourses`);
            if (response.data.data) {
                setCourseData(response.data.data);
            }
        } catch (error) {
            console.error('Error fetching courses:', error);
        }
    };

    // handleSave function to save coupon data
    const handleSave = async (values) => {
        // Api Calling
        let response = await userApi.post(`${ConfigData.SERVER_URL}/admin/coupons/addCouponCode`, values)
        if (response.data.status == false) {
            throw Error(response.data.message);
        }

        resetForm();  //reset form after submitting

        let toast = {
            "status": true,
            "body": "Coupon Added Successfully",
            "message": "Success"
        }

        addToaster(toast)
    }

    // reset form function to handle form reset
    const resetForm = () => {
        formRef.current.resetForm();
    };

    return (
        <>
            <CRow>
                <CCol sm={12} className='pb-4'>
                    <CCard style={{ background: "white" }}>
                        <CCardBody>
                            <CRow>
                                <CCol sm={2} className="d-flex justify-content-start">
                                    <CButton style={{ backgroundColor: 'rgb(214, 178, 49)', color: 'black' }} onClick={() => history.push({ pathname: '/pages/analytics/generateCoupon' })}>Back</CButton>
                                </CCol>

                                <CCol sm={6} className="d-flex justify-content-center">
                                    <CCardTitle style={{ color: "black" }}>Create Coupon Code</CCardTitle>
                                </CCol>
                            </CRow>
                        </CCardBody>
                    </CCard>
                </CCol>
            </CRow>
            <CCard>
                <CCardBody>
                    <Formik
                        initialValues={{ couponType: '', lotName: '', courses: '', startDate: '', endDate: '', couponCode: '', amount: '', newLotName: '' }}
                        validationSchema={moduleSchema}
                        onSubmit={(values) => {
                            handleSave(values);
                        }}
                        innerRef={formRef}
                    >
                        {(formikProps) => (
                            <CForm>
                                <CRow>
                                    <CCol xs={4}>
                                        <CFormLabel htmlFor="userRegistration"><b>Select Coupon Type : </b><span style={{ color: 'red' }}>*</span></CFormLabel>
                                        <CFormSelect aria-label="Default select example" id='userRegistration'
                                            onChange={formikProps.handleChange('couponType')}
                                            value={formikProps.values.couponType}>
                                            <option value='' disabled={true}>Select Coupon Type</option>
                                            <option value="Unique">Unique</option>
                                            <option value="Universal">Universal</option>
                                        </CFormSelect>
                                        <p style={{ color: 'red' }}>{formikProps.touched.couponType && formikProps.errors.couponType}</p>
                                    </CCol>

                                    <CCol xs={4}>
                                        <CFormLabel><b>Lot Name:</b><span style={{ color: 'red' }}>*</span></CFormLabel>
                                        <CFormSelect aria-label="Default select example" onChange={(e) => {
                                            formikProps.handleChange('lotName')(e);
                                            handleSetLotName(e);
                                        }}
                                            value={formikProps.values.lotName}>

                                            <option value='' disabled={true}>Select Lot Name</option>
                                            {lotOptions && lotOptions.map((data, index) => (
                                                <option key={index} value={data.lotName}>{data.lotName}</option>
                                            ))}
                                            <option value="others">Others</option>

                                        </CFormSelect>
                                        <p style={{ color: 'red' }}>{formikProps.touched.lotName && formikProps.errors.lotName}</p>

                                    </CCol>
                                    {lotNameInput &&
                                        <CCol xs={4}>
                                            <CFormLabel><b>Lot Name:</b><span style={{ color: 'red' }}>*</span></CFormLabel>
                                            <CFormInput aria-label="Default select example" onChange={formikProps.handleChange('newLotName')}
                                                value={formikProps.values.newLotName} placeholder='Type Lot Name' />
                                            <p style={{ color: 'red' }}>{formikProps.touched.newLotName && formikProps.errors.newLotName}</p>
                                        </CCol>
                                    }

                                    <CCol xs={4}>
                                        {/* Dropdown for course selection */}
                                        <CFormLabel htmlFor="selectCourse"><b>Select Course: </b><span style={{ color: 'red' }}>*</span></CFormLabel>
                                        <CFormSelect aria-label="Default select example" id='selectCourse'
                                            onChange={formikProps.handleChange('courses')}
                                            value={formikProps.values.courses}>
                                            <option>Select Course</option>
                                            {courseData && courseData.map((course, index) => (
                                                <option key={index} value={JSON.stringify({ name: course.courseName, id: course._id })} >{course.courseName}</option>
                                            ))}
                                        </CFormSelect>
                                        <p style={{ color: 'red' }}>{formikProps.touched.courses && formikProps.errors.courses}</p>
                                    </CCol>

                                    <CCol xs={4}>
                                        <CFormLabel htmlFor="selectCourse"><b>Lot Start Date: </b><span style={{ color: 'red' }}>*</span></CFormLabel>
                                        <input type="date" className='form-control' label="Start Date" name="startDate"
                                            onChange={formikProps.handleChange('startDate')}
                                            value={formikProps.values.startDate} />
                                        <p style={{ color: 'red' }}>{formikProps.touched.startDate && formikProps.errors.startDate}</p>

                                    </CCol>
                                    <CCol xs={4}>
                                        <CFormLabel htmlFor="selectCourse"><b>Lot End Date: </b><span style={{ color: 'red' }}>*</span></CFormLabel>
                                        <input type="date" className='form-control' label="End Date" name="enddate"
                                            onChange={formikProps.handleChange('endDate')}
                                            value={formikProps.values.endDate} />
                                        <p style={{ color: 'red' }}>{formikProps.touched.endDate && formikProps.errors.endDate}</p>
                                    </CCol>
                                </CRow>

                                <CRow>

                                    <CCol xs={6}>
                                        {/* Input Field for type coupon code */}
                                        <CFormLabel><b>Enter coupon code: </b><span style={{ color: 'red' }}>*</span></CFormLabel>
                                        <CFormInput type="text" placeholder="Type coupon code" aria-label="default input example"
                                            onChange={formikProps.handleChange('couponCode')}
                                            value={formikProps.values.couponCode} />
                                        <p style={{ color: 'red' }}>{formikProps.touched.couponCode && formikProps.errors.couponCode}</p>
                                    </CCol>
                                    <CCol xs={6}>
                                        {/* Input field for type amount */}
                                        <CFormLabel><b>Enter amount: </b><span style={{ color: 'red' }}>*</span></CFormLabel>
                                        <CFormInput type="number" placeholder="Enter amount" aria-label="default input example"
                                            onChange={formikProps.handleChange('amount')}
                                            value={formikProps.values.amount} />
                                        <p style={{ color: 'red' }}>{formikProps.touched.amount && formikProps.errors.amount}</p>
                                    </CCol>
                                </CRow>
                                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', paddingTop: '10px' }}>
                                    <div style={{}}>
                                        <CButton color="success" onClick={formikProps.handleSubmit}>Add Coupon</CButton>
                                    </div>
                                    <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                                        <div style={{ marginRight: '20px' }}>
                                            <CButton color="success" onClick={handleDownloadExcel}>Download Excel Format</CButton>
                                        </div>
                                        <div style={{ marginRight: '10px' }}>
                                            <CFormInput type="file" placeholder="Upload Excel" onChange={handleFileChange} accept=".xlsx, .xls" aria-label="default input example" />
                                        </div>
                                        <div style={{}}>
                                            <CButton color="success" onClick={handleUploadExcel} disabled={selectedFile === null}>Upload Excel</CButton>
                                        </div>
                                    </div>
                                </div>
                                <br />
                            </CForm>
                        )}
                    </Formik>
                </CCardBody>
            </CCard>
            {
                toast && <Toaster message={toast.message} status={toast.status} body={toast.body} ></Toaster>
            }
        </>
    )
}

export default AddCoupon
