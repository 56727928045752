import React, { useRef, useState, useEffect } from 'react'
import userApi from 'src/utils/axios';
import { Formik } from "formik";
import * as yup from 'yup';
import ConfigData from './../../../../config/constant.json'
import {
    CCard, CCardBody, CCol, CRow, CCardText, CCardTitle,
    CModalHeader, CModal, CModalFooter, CFormTextarea, CFormSelect, CInputGroup,
    CModalTitle, CModalBody, CContainer, CCardGroup, CCardImage,
    CForm, CFormInput,
} from '@coreui/react'
import Select from "react-select";


const AddInstitute = ({ visible, onClose, addToast, addinstituteCallback }) => {

    useEffect(() => {
        getCollegeData();
    }, [])

    const [options, setOptions] = useState([]);
    const [selectedInstitute, setSelectedInstitute] = useState(false);


    const instituteSchema = yup.object({
        instituteId: yup.number()
            .required('Institute Name is Required'),
        instituteName: selectedInstitute ? yup.string()
            .required('Institute Name is Required').min(5) : yup.string(),
        instituteShortName: yup.string()
            .required('Institute Short Name is Required'),
        aboutInstitute: yup.string()
            .required('About Institute is Required').min(10),
    });

    const handleAddInstitute = async (values) => {

        try {
            let response = await userApi.post(`${ConfigData.SERVER_URL}/admin/institute/addInstitute`, { values })

            if (response.data.status === false) {
                addinstituteCallback('');
                addToast({ 'body': response.data.message, 'message': 'Danger', 'status': false });
                setTimeout(() => {
                    addToast(false);
                }, 2000)
            } else {
                addinstituteCallback(response.data.data);
                addToast({ "status": true, "body": "Institute Added", "message": "Success" })
                setTimeout(() => {
                    addToast(false);
                }, 2000)
            }
        } catch (e) {
            addToast({ "status": false, "body": "Adding Institute Failed", "message": "Failure" })
            setTimeout(() => {
                addToast(false);
            }, 2000)
        }
    }

    const handleSelectInstitute = async (value) => {
        setSelectedInstitute(value === '0' ? true : false);
    }

    const getCollegeData = async () => {

        try {
            let response = await userApi.get(`${ConfigData.SERVER_URL}/admin/institute/getColleges`);
            if (response.status === 200) {
                const optionsData = response.data.data.map(user => ({
                    value: user.collegeCode,
                    label: user.name
                }));

                optionsData.push({
                    value: '0', // Value for "Others"
                    label: 'Others' // Label for "Others"
                });

                setOptions(optionsData);
            } else {
                setOptions([]);
            }

        } catch (e) {
            console.error(e);
        }
    }

    const handleClearSelection = () => {
        setSelectedInstitute(false);
    }

    return (
        <>
            <CModal visible={visible} alignment="center" onClose={onClose} backdrop='static'>
                <CModalHeader onClose={onClose}>
                    <CModalTitle>Add Institute</CModalTitle>
                </CModalHeader>
                <CModalBody>
                    <>
                        <CCardBody>
                            <Formik
                                initialValues={{ instituteName: '', instituteShortName: '', aboutInstitute: '' }}
                                validationSchema={instituteSchema}
                                onSubmit={(values, actions) => {
                                    handleAddInstitute(values);
                                    actions.resetForm();
                                }}
                            >
                                {(formikProps) => (
                                    <CForm>
                                        <span className="mb-3">
                                            <legend>Institute</legend>
                                            <Select
                                                id='selectUser'
                                                options={options}
                                                onChange={(selectedOption) => {
                                                    if (selectedOption) {
                                                        // If an option is selected
                                                        formikProps.setFieldValue('instituteId', selectedOption.value);
                                                        handleSelectInstitute(selectedOption.value);
                                                    } else {
                                                        // If selection is cleared
                                                        formikProps.setFieldValue('instituteId', ''); // Reset instituteId
                                                        handleClearSelection(); // Call the function when cleared
                                                    }
                                                }}
                                                isClearable={true}
                                                isSearchable={true}
                                                placeholder='Select Institute'
                                            />
                                        </span>
                                        <p style={{ color: 'red' }}>{formikProps.touched.instituteId && formikProps.errors.instituteId}</p>

                                        {selectedInstitute &&
                                            <>
                                                <CInputGroup>
                                                    <CFormInput
                                                        placeholder="eg. HSNC UNIVERSITY"
                                                        autoComplete="instituteName"
                                                        onChange={formikProps.handleChange('instituteName')}
                                                        value={formikProps.values.instituteName}
                                                        onBlur={formikProps.handleBlur('instituteName')}
                                                    />
                                                </CInputGroup>
                                                <p style={{ color: 'red' }}>{formikProps.touched.instituteName && formikProps.errors.instituteName}</p>
                                            </>
                                        }

                                        <CInputGroup className="mb-3">
                                            <legend>Institute Short Name</legend>
                                            <CFormInput
                                                placeholder="eg. IIM-A"
                                                autoComplete="instituteShortName"
                                                onChange={formikProps.handleChange('instituteShortName')}
                                                value={formikProps.values.instituteShortName}
                                                onBlur={formikProps.handleBlur('instituteShortName')}
                                            />
                                        </CInputGroup>
                                        <p style={{ color: 'red' }}>{formikProps.touched.instituteShortName && formikProps.errors.instituteShortName}</p>

                                        <CInputGroup className="mb-3">
                                            <legend>About Institute</legend>
                                            <CFormTextarea
                                                id="aboutInstitute"
                                                rows="3"
                                                placeholder='About Institute'
                                                text="Must be 8-20 words long."
                                                onChange={formikProps.handleChange('aboutInstitute')}
                                                value={formikProps.values.aboutInstitute}
                                                onBlur={formikProps.handleBlur('aboutInstitute')}
                                            ></CFormTextarea>
                                        </CInputGroup>
                                        <p style={{ color: 'red' }}>{formikProps.touched.aboutInstitute && formikProps.errors.aboutInstitute}</p>

                                        <CRow >
                                            <CCol xs={5}> </CCol>
                                            <CCol xs={2}>
                                                <button onClick={formikProps.handleSubmit} type="submit" className="btn btn-outline-primary">Submit</button> </CCol>

                                            <CCol xs={5}> </CCol>

                                        </CRow>
                                    </CForm>
                                )}
                            </Formik>
                        </CCardBody>
                    </>
                </CModalBody>
            </CModal>
        </>
    );
}

export default AddInstitute;