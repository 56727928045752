import React, { useState, useEffect, useRef } from 'react';
import { CRow, CCol, CCard, CCardBody, CButton, CForm, CFormLabel, CFormInput, CFormSelect } from '@coreui/react';
import { Formik } from "formik";
import * as yup from 'yup';
import userApi from 'src/utils/axios';
import ConfigData from 'src/config/constant.json';
import Multiselect from 'multiselect-react-dropdown';
import Toaster from 'src/views/shared/Toaster';

const AddQuiz = () => {
    const formRef = useRef(null);
    const [courseData, setCourseData] = useState([]);
    const [batchData, setBatchData] = useState([]);
    const [statusData, setStatusData] = useState([]);
    const [moduleVideoData, setModuleVideoData] = useState([]);
    const [selectedCourse, setSelectedCourse] = useState(null);
    const [selectedBatch, setSelectedBatch] = useState(null);
    const [toast, addToast] = useState(false);

    // Fetch courses and status data on component mount
    useEffect(() => {
        let isMounted = true; // Track if component is mounted
        const fetchInitialData = async () => {
            await getAllCourses();
            await getStatusData();
        };
        fetchInitialData();
        return () => { isMounted = false }; // Cleanup to prevent state updates if unmounted
    }, []);

    // Form validation schema using Yup
    const moduleSchema = yup.object({
        quizName: yup.string().required('Quiz Name is required'),
        courseId: yup.string().required('Course is required'),
        batchId: yup.string().required('Batch is required'),
        videoIds: yup.array().min(1, 'Select at least one video').required('Videos are required'),
        status: yup.string().required('Status is required'),
        noOfQuestion: yup.number().required('No. of Questions is required').positive('Must be a positive number').integer('Must be an integer'),
    });

    const getAllCourses = async () => {
        try {
            const response = await userApi.get(`${ConfigData.SERVER_URL}/admin/course/getcourses`);
            if (response.data.data) {
                setCourseData(response.data.data);
            }
        } catch (error) {
            console.error('Error fetching courses:', error);
        }
    };

    const fetchBatchData = async (batchId) => {
        try {
            if (batchId && selectedCourse) {
                const response = await userApi.get(`${ConfigData.SERVER_URL}/admin/batch/getModuleVideos?courseId=${selectedCourse}&batchId=${batchId}`);
                setModuleVideoData(response.data.data);
                setSelectedBatch(batchId);
            }
        } catch (error) {
            console.error('Error fetching batch data:', error);
        }
    };

    const fetchCourseData = async (courseId) => {
        try {
            setSelectedCourse(courseId);
            setBatchData([]);
            const response = await userApi.get(`${ConfigData.SERVER_URL}/admin/batch/getBatchByCourseId?courseId=${courseId}`);
            if (response.data.data) {
                setBatchData(response.data.data);
            }
        } catch (error) {
            console.error('Error fetching course data:', error);
        }
    };

    const getStatusData = async () => {
        try {
            const status = [{ name: 'ACTIVE', value: 'ACTIVE' }, { name: 'INACTIVE', value: 'INACTIVE' }];
            setStatusData(status);
        } catch (error) {
            console.error('Error fetching status data:', error);
        }
    };

    const handleSave = async (values) => {
        console.log('Submitted values:', values);
        // Add your API call here to save the 
        const response = await userApi.post(`${ConfigData.SERVER_URL}/admin/quiz/addQuiz`, values);
        console.log('response', response.data);
        if (response.data.status === true) {
            resetForm();
            addToaster({ status: true, message: "Success", body: response.data.message });
        } else {
            addToaster({ status: false, message: "Failure", body: response.data.message });
        }
    };

    // reset form function to handle form reset
    const resetForm = () => {
        formRef.current.resetForm();
        formRef.current.setFieldValue('videoIds', []); // Clear selected values in Multiselect
        setSelectedCourse(null);
        setSelectedBatch(null);

    };

    const addToaster = (toast) => {
        addToast(toast)
        setTimeout(() => {
            addToast(false)
        }, 3000)
    }

    return (
        <>

            <CCard>
                <CCardBody>
                    <Formik
                        initialValues={{
                            quizName: '',
                            courseId: '',
                            batchId: '',
                            videoIds: [],
                            status: '',
                            noOfQuestion: ''
                        }}
                        validationSchema={moduleSchema}
                        onSubmit={(values, { setSubmitting }) => {
                            setSubmitting(false);
                            handleSave(values);
                        }}
                        innerRef={formRef}
                    >
                        {(formikProps) => (
                            <CForm onSubmit={formikProps.handleSubmit}>
                                <CRow>
                                    {/* Quiz Name */}
                                    <CCol xs={4}>
                                        <CFormLabel htmlFor="quizName"><b>Quiz:</b> <span style={{ color: 'red' }}>*</span></CFormLabel>
                                        <CFormInput
                                            type="text"
                                            name="quizName"
                                            placeholder="Enter Quiz Name"
                                            value={formikProps.values.quizName}
                                            onChange={formikProps.handleChange}
                                            onBlur={formikProps.handleBlur}
                                        />
                                        {formikProps.touched.quizName && formikProps.errors.quizName && <p style={{ color: 'red' }}>{formikProps.errors.quizName}</p>}
                                    </CCol>

                                    {/* Course Select */}
                                    <CCol xs={4}>
                                        <CFormLabel htmlFor="courseId"><b>Course:</b> <span style={{ color: 'red' }}>*</span></CFormLabel>
                                        <CFormSelect
                                            id='selectCourse'
                                            name="courseId"
                                            onChange={(event) => {
                                                formikProps.setFieldValue('courseId', event.target.value);
                                                fetchCourseData(event.target.value);
                                            }}
                                            value={formikProps.values.courseId}
                                        >
                                            <option disabled value=''>Select Course</option>
                                            {courseData.map((course, index) => (
                                                <option key={index} value={course._id}>{course.courseName}</option>
                                            ))}
                                        </CFormSelect>
                                        {formikProps.touched.courseId && formikProps.errors.courseId && <p style={{ color: 'red' }}>{formikProps.errors.courseId}</p>}
                                    </CCol>

                                    {/* Batch Select */}
                                    <CCol xs={4}>
                                        <CFormLabel htmlFor="batchId"><b>Select Batch:</b> <span style={{ color: 'red' }}>*</span></CFormLabel>
                                        <CFormSelect
                                            id='selectBatch'
                                            name="batchId"
                                            onChange={(event) => {
                                                formikProps.setFieldValue('batchId', event.target.value);
                                                fetchBatchData(event.target.value);
                                            }}
                                            value={formikProps.values.batchId}
                                            disabled={!selectedCourse}
                                            style={{ cursor: !selectedCourse ? 'not-allowed' : 'auto' }}
                                        >
                                            <option disabled value=''>Select Batch</option>
                                            {batchData.map((batch, index) => (
                                                <option key={index} value={batch._id}>{batch.batchName}</option>
                                            ))}
                                        </CFormSelect>
                                        {formikProps.touched.batchId && formikProps.errors.batchId && <p style={{ color: 'red' }}>{formikProps.errors.batchId}</p>}
                                    </CCol>
                                </CRow>

                                {selectedBatch && (
                                    <>
                                        <CRow>
                                            {/* Video Multiselect */}
                                            <CCol xs={4}>
                                                <CFormLabel htmlFor="videoIds"><b>Activate After Completing:</b> <span style={{ color: 'red' }}>*</span></CFormLabel>
                                                <Multiselect
                                                    displayValue="originalName"
                                                    groupBy="module_name"
                                                    options={moduleVideoData}
                                                    showCheckbox
                                                    onSelect={(selectedList) => formikProps.setFieldValue('videoIds', selectedList.map(item => item.videoId))}
                                                    onRemove={(selectedList) => formikProps.setFieldValue('videoIds', selectedList.map(item => item.videoId))}
                                                />
                                                {formikProps.touched.videoIds && formikProps.errors.videoIds && <p style={{ color: 'red' }}>{formikProps.errors.videoIds}</p>}
                                            </CCol>

                                            {/* Status Select */}
                                            <CCol xs={4}>
                                                <CFormLabel htmlFor="status"><b>Status:</b> <span style={{ color: 'red' }}>*</span></CFormLabel>
                                                <CFormSelect
                                                    name="status"
                                                    value={formikProps.values.status}
                                                    onChange={formikProps.handleChange}
                                                    onBlur={formikProps.handleBlur}
                                                >
                                                    <option disabled value=''>Select Status</option>
                                                    {statusData.map((status, index) => (
                                                        <option key={index} value={status.value}>{status.name}</option>
                                                    ))}
                                                </CFormSelect>
                                                {formikProps.touched.status && formikProps.errors.status && <p style={{ color: 'red' }}>{formikProps.errors.status}</p>}
                                            </CCol>

                                            {/* No. of Questions */}
                                            <CCol xs={4}>
                                                <CFormLabel htmlFor="noOfQuestion"><b>No. of Questions:</b> <span style={{ color: 'red' }}>*</span></CFormLabel>
                                                <CFormInput
                                                    type="number"
                                                    name="noOfQuestion"
                                                    placeholder="Enter Limit of Questions"
                                                    value={formikProps.values.noOfQuestion}
                                                    onChange={formikProps.handleChange}
                                                    onBlur={formikProps.handleBlur}
                                                />
                                                {formikProps.touched.noOfQuestion && formikProps.errors.noOfQuestion && <p style={{ color: 'red' }}>{formikProps.errors.noOfQuestion}</p>}
                                            </CCol>
                                        </CRow>

                                        {/* Submit Button */}
                                        <CRow>
                                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: '20px' }}>
                                                <CButton
                                                    type="submit"
                                                    style={{ width: '200px', cursor: !formikProps.isValid ? 'not-allowed' : 'auto' }}
                                                    color="success"
                                                    disabled={!formikProps.isValid || formikProps.isSubmitting}
                                                >
                                                    Add Quiz
                                                </CButton>
                                            </div>
                                        </CRow>
                                    </>
                                )}
                            </CForm>
                        )}
                    </Formik>
                </CCardBody>
            </CCard>

            {
                toast && <Toaster message={toast.message} status={toast.status} body={toast.body} ></Toaster>
            }
        </>
    );
};

export default AddQuiz;
