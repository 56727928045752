import React, { useEffect, useState } from 'react';
import { CRow, CCol, CCard, CCardBody, CButton, CCardTitle, CFormInput, CFormSelect, CAvatar, CModal, CModalHeader, CModalTitle, CModalBody, CModalFooter } from '@coreui/react/dist/index.js';
import AddQuestion from './AddQuestion';
import { cilSearch } from '@coreui/icons';
import CIcon from '@coreui/icons-react';
import LoadingScreen from "src/views/shared/Loading";
import { Table } from 'react-bootstrap';
import userApi from 'src/utils/axios';
import ConfigData from '../../../config/constant.json';
import ReactPaginate from 'react-paginate';
import Quiz from './Quiz';
import Toaster from 'src/views/shared/Toaster';

const Question = ({ quizId, limitOfQuestion }) => {
    const [question, setQuestion] = useState(false);
    const [role, setRole] = useState(true);
    const [debouncedValue, setDebouncedValue] = useState('');
    let timeoutId; // Declare timeoutId variable outside of debounce function
    const itemsPerPage = 10;
    const [loader, setLoader] = useState(true);
    const [offset, setOffset] = useState(0);
    const [pageCount, setPageCount] = useState(0);
    const [count, setCount] = useState(0);
    const [questionData, setQuestionData] = useState([]);
    const [viewQuestion, setViewQuestion] = useState(true);
    const [deleteVisible, setDeleteVisible] = useState(false);
    const [updatedQuestionId, setUpdatedQuestionId] = useState('');
    const [toast, addToast] = useState(false);
    const [visible, setVisible] = useState(false);
    const [initialData, setInitialData] = useState([]);

    useEffect(() => {
        if (!question) {
            fetchQuestionData('');
            const userRoles = localStorage.getItem("role");
            if (userRoles === 'VIEWER' || userRoles === 'TEACHER') {
                setRole(false);
            } else {
                setRole(true);
            }
        }
    }, [question, visible, offset])

    // Handle search input change with debounce
    const handleSearch = (e) => {
        debounce(e.target.value, 500);
    }

    // Debounce function to delay search input
    const debounce = (value, delay) => {

        clearTimeout(timeoutId); // Clear the previous timeout if exists

        timeoutId = setTimeout(() => {
            setDebouncedValue(value);
        }, delay);

        // Cleanup function to clear the timeout on unmount or new debounce call
        return () => clearTimeout(timeoutId);
    }

    // Handle pagination click
    const handlePageClick = (e) => {
        setOffset(e.selected)
    }

    const handleDeleteQuestion = (questionId) => {
        setUpdatedQuestionId(questionId)
        setDeleteVisible(true);
    }

    const handleEditQuestion = (questionData) => {
        setInitialData(questionData);
        setVisible(true);
    }

    const closeUpdateModal = async () => {
        setVisible(false);
    }

    const closeDeleteModel = async () => {
        setDeleteVisible(false);
    }

    const deleteQuestion = async () => {
        try {
            setLoader(true);
            await userApi.delete(`${ConfigData.SERVER_URL}/admin/quiz/deleteQuestion?questionId=${updatedQuestionId}`);
            setDeleteVisible(false);
            addToaster({ status: true, message: "Success", body: 'Question Deleted Successfully!' });
            fetchQuestionData('');

        } catch (e) {
            addToaster({ status: false, message: "Failure", body: 'Internal Server error' });
            console.error(e.message);
        }
        setLoader(false);
    }

    const addToaster = (toast) => {
        addToast(toast)
        setTimeout(() => {
            addToast(false)
        }, 3000)
    }

    const fetchQuestionData = async (searchTerm) => {
        try {
            setLoader(true);
            const response = await userApi.get(`${ConfigData.SERVER_URL}/admin/quiz/getQuestionByQuizId?quizId=${quizId}&searchTerm=${searchTerm}&offset=${offset}`);
            if (response.data?.data) {
                setQuestionData(response.data?.data);
                setPageCount(Math.ceil(response.data?.data?.length / itemsPerPage));
                setCount(response.data?.data?.length)
            }
            setLoader(false); // Set loader to false after data fetching

        } catch (e) {
            // setError(`${e.message} :(`)
            setLoader(false) // Set loader to false on error
        }
    }

    const setQuestionViews = (value) => {
        if (value === true) {
            if (limitOfQuestion === questionData.length) {
                addToaster({ status: false, message: "Failure", body: 'Update the number of questions in the quiz to add more.' });
            } else {
                setQuestion(value);
            }
        } else {
            setQuestion(value);
        }

    }

    return (
        <>
            {
                viewQuestion &&
                <>
                    <CRow>
                        <CCol sm={12} className='pb-4'>
                            <CCard style={{ background: "#fff" }}>
                                <CCardBody>
                                    <CRow>
                                        <CCol sm={4}>
                                            {!question && <CButton style={{ backgroundColor: 'rgb(214, 178, 49)', color: 'black' }} onClick={() => setViewQuestion(false)}>Back</CButton>}
                                            {question && <CButton style={{ backgroundColor: 'rgb(214, 178, 49)', color: 'black' }} onClick={() => setQuestionViews(false)}>Back</CButton>}

                                        </CCol>
                                        <CCol sm={4}>
                                            <CCardTitle style={{ color: "#000" }}>{question ? 'Add Question' : 'Question'}</CCardTitle>
                                        </CCol>
                                        {role &&
                                            <CCol sm={4} className="d-flex justify-content-end" >
                                                {!question && <CButton style={{ backgroundColor: 'rgb(214, 178, 49)', color: 'black' }} onClick={() => setQuestionViews(true)}>Add Question</CButton>}
                                            </CCol>
                                        }
                                    </CRow>
                                </CCardBody>
                            </CCard>
                        </CCol>
                    </CRow>

                    {
                        !question &&
                        <>
                            < div >
                                {
                                    !loader ?
                                        <>
                                            <h5 style={{ marginBottom: '1%', marginLeft: '2%' }}>Quiz Count: <span ><b>{count}</b></span></h5>
                                            <div style={{ marginLeft: '2%', marginRight: '2%' }}>
                                                <Table striped bordered hover id='user-table'>
                                                    <thead>
                                                        <tr>
                                                            <th>Sr. No</th>
                                                            <th>Question</th>
                                                            <th>Answer</th>
                                                            <th>Action</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {questionData.length > 0 &&
                                                            questionData.map((item, index) => (
                                                                <tr key={item._id}>
                                                                    <td>{offset * 10 + (index + 1)}</td>
                                                                    <td>{item?.question}</td>
                                                                    <td>  {item.options.filter(option => option.isCorrect === true).map(option => option.answer)}</td>
                                                                    <td style={{ textAlign: "center" }}>
                                                                        <div>
                                                                            <CButton style={{ backgroundColor: '#14752a', color: 'white' }} onClick={() => handleEditQuestion(item)} >Update</CButton>
                                                                            <CButton style={{ marginLeft: '10px', backgroundColor: '#ab2f0c', color: 'white' }} onClick={() => handleDeleteQuestion(item._id)} >Delete</CButton>
                                                                        </div></td>
                                                                </tr>
                                                            ))
                                                        }
                                                    </tbody>
                                                </Table>

                                                {/* Pagination Component */}
                                                <ReactPaginate
                                                    previousLabel={"previous"}
                                                    nextLabel={"next"}
                                                    breakLabel={"..."}
                                                    pageCount={pageCount}
                                                    marginPagesDisplayed={2}
                                                    pageRangeDisplayed={3}
                                                    onPageChange={(event) => { handlePageClick(event) }}
                                                    containerClassName={"pagination justify-content-center"}
                                                    pageClassName={"page-item"}
                                                    pageLinkClassName={"page-link"}
                                                    previousClassName={"page-item"}
                                                    previousLinkClassName={"page-link"}
                                                    nextClassName={"page-item"}
                                                    nextLinkClassName={"page-link"}
                                                    breakClassName={"page-item"}
                                                    breakLinkClassName={"page-link"}
                                                    activeClassName={"active"}
                                                    forcePage={offset}
                                                />
                                            </div>
                                        </>
                                        : <LoadingScreen />
                                }

                            </div >
                        </>
                    }

                    {question &&
                        <AddQuestion quizId={quizId} />
                    }
                </>
            }

            {
                !viewQuestion &&
                <Quiz />
            }

            {
                toast && <Toaster message={toast.message} status={toast.status} body={toast.body} ></Toaster>
            }

            {/* Delete Modal */}
            <CModal
                visible={deleteVisible}
                backdrop="static"
                onClose={closeDeleteModel}
                alignment="center"
                aria-labelledby="StaticBackdropExampleLabel"
            >
                <CModalHeader>
                    <CModalTitle id="StaticBackdropExampleLabel">Delete Confirmation</CModalTitle>
                </CModalHeader>
                <CModalBody>
                    <span style={{ fontSize: '20px', fontWeight: 'bold' }}>Are you sure you want to delete this Question?</span>
                </CModalBody>
                <CModalFooter>
                    <CButton style={{ backgroundColor: '#14752a', color: 'white' }} onClick={deleteQuestion}>Yes</CButton>
                    <CButton style={{ backgroundColor: '#ab2f0c', color: 'white' }} onClick={closeDeleteModel}>
                        No
                    </CButton>
                </CModalFooter>
            </CModal>


            {/* Update Modal  */}
            <CModal
                size='lg'
                backdrop="static"
                visible={visible}
                onClose={() => setVisible(false)}
                alignment="center"
                aria-labelledby="StaticBackdropExampleLabel"
            >
                <CModalHeader>
                    <CModalTitle id="StaticBackdropExampleLabel">Update Question</CModalTitle>
                </CModalHeader>
                <CModalBody>
                    <AddQuestion initialData={initialData} closeModal={() => setVisible(false)} />
                </CModalBody>
                <CModalFooter>
                    <CButton color="secondary" onClick={closeUpdateModal}>
                        Close
                    </CButton>
                </CModalFooter>
            </CModal>
        </>
    );
}

export default Question;