import React, { useState, useEffect } from "react";
import { CRow, CCol, CCard, CCardBody, CButton, CCardTitle, CFormInput, CFormSelect, CModal, CModalHeader, CModalTitle, CModalBody, CModalFooter } from '@coreui/react/dist/index.js';
import { cilSearch } from '@coreui/icons';
import CIcon from '@coreui/icons-react';
import { Table } from 'react-bootstrap';
import ReactPaginate from 'react-paginate';
import userApi from 'src/utils/axios';
import ConfigData from '../../../config/constant.json';
import LoadingScreen from "src/views/shared/Loading";
import AnalyticsData from "./AnalyticsData";

const OverAllAnalytics = () => {

    const [debouncedValue, setDebouncedValue] = useState('');
    let timeoutId; // Declare timeoutId variable outside of debounce function
    const [offset, setOffset] = useState(0);
    const [pageCount, setPageCount] = useState(0);
    const [count, setCount] = useState(0);
    const [loader, setLoader] = useState(true);
    const [error, setError] = useState(false);
    const [users, setUsers] = useState([]);
    const itemsPerPage = 10;
    const [view, setView] = useState(false);
    const [courseId, setCourseId] = useState('');
    const [batchId, setBatchId] = useState('');
    const [courseName, setCourseName] = useState('');
    const [batchName, setBatchName] = useState('');

    useEffect(() => {
        if (debouncedValue !== '') {
            getAnalyticsData(debouncedValue);
        } else {
            getAnalyticsData('');
        }

    }, [debouncedValue, offset]);


    const getAnalyticsData = async (searchTerm) => {
        try {
            setLoader(true);

            const response = await userApi.get(`${ConfigData.SERVER_URL}/batches/getAllAnalyticsData?searchTerm=${searchTerm}&offset=${offset}`);
            if (response.data?.data) {
                setUsers(response.data?.data?.analyticsData);

                setPageCount(Math.ceil(response.data?.data?.dataCount / itemsPerPage));
                setCount(response.data?.data?.dataCount)
            }
            setLoader(false); // Set loader to false after data fetching
        } catch (error) {
            setError(`${error.message} :(`)
            setLoader(false) // Set loader to false on error     
        }

    }

    // Handle pagination click
    const handlePageClick = (e) => {
        setOffset(e.selected)
    }

    // Handle search input change with debounce
    const handleSearch = (e) => {
        debounce(e.target.value, 500);
    }

    // Debounce function to delay search input
    const debounce = (value, delay) => {

        clearTimeout(timeoutId); // Clear the previous timeout if exists

        timeoutId = setTimeout(() => {
            setDebouncedValue(value);
        }, delay);

        // Cleanup function to clear the timeout on unmount or new debounce call
        return () => clearTimeout(timeoutId);
    }

    const viewAnalytics = (courseId, batchId, courseName, batchName) => {
        setView(true);
        setCourseId(courseId);
        setBatchId(batchId);
        setCourseName(courseName);
        setBatchName(batchName);
    }




    return (
        <>{!view && <>
            <CRow>
                <CCol sm={12} className='pb-4'>
                    <CCard style={{ background: "#fff" }}>
                        <CCardBody>
                            <CRow>
                                <CCol sm={6}>
                                    <CCardTitle style={{ color: "#000" }}>Analytics Details</CCardTitle>
                                </CCol>
                            </CRow>
                        </CCardBody>
                    </CCard>
                </CCol>
            </CRow>

            <CRow>
                <CCol md={6} style={{ paddingLeft: '30px' }}>

                    <div style={{ position: 'relative', marginBottom: '1%', marginLeft: '2%' }}>
                        <div style={{ position: 'absolute', top: '50%', left: '10px', transform: 'translateY(-50%)' }}>
                            <CIcon icon={cilSearch} size="xl" />
                        </div>

                        <CFormInput
                            placeholder="Search..."
                            autoComplete="Search"
                            style={{ paddingLeft: '40px' }} // Adjust paddingLeft to make space for the icon
                            onChange={handleSearch}
                        />
                    </div>
                </CCol>
            </CRow >

            {!loader ?
                <>
                    <h5 style={{ marginBottom: '1%', marginLeft: '2%' }}>Total Count: <span ><b>{count}</b></span></h5>
                    <div style={{ marginLeft: '2%', marginRight: '2%' }}>
                        <Table striped bordered hover id='user-table'>
                            <thead>
                                <tr>
                                    <th>Sr. No</th>
                                    <th>Institute Name</th>
                                    <th>Course Name</th>
                                    <th>Batch Name</th>
                                    <th>Mode</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {users.length > 0 &&
                                    users.map((item, index) => (
                                        <tr key={item._id}>
                                            <td>{offset * 10 + (index + 1)}</td>
                                            <td>{item?.Institute?.instituteName}</td>
                                            <td>{item.course?.courseName}</td>
                                            <td>{item.batchName}</td>
                                            <td>{item.mode}</td>
                                            <td><CButton style={{ backgroundColor: 'rgb(214, 178, 49)', color: 'black' }} onClick={() => { viewAnalytics(item.course._id, item._id, item.course.courseName, item.batchName) }}>View</CButton></td>
                                        </tr>
                                    ))
                                }
                            </tbody>
                        </Table>

                        {/* Pagination Component */}
                        <ReactPaginate
                            previousLabel={"previous"}
                            nextLabel={"next"}
                            breakLabel={"..."}
                            pageCount={pageCount}
                            marginPagesDisplayed={2}
                            pageRangeDisplayed={3}
                            onPageChange={(event) => { handlePageClick(event) }}
                            containerClassName={"pagination justify-content-center"}
                            pageClassName={"page-item"}
                            pageLinkClassName={"page-link"}
                            previousClassName={"page-item"}
                            previousLinkClassName={"page-link"}
                            nextClassName={"page-item"}
                            nextLinkClassName={"page-link"}
                            breakClassName={"page-item"}
                            breakLinkClassName={"page-link"}
                            activeClassName={"active"}
                            forcePage={offset}
                        />
                    </div>
                </> : <LoadingScreen />
            }
        </>}

            {
                view && <>
                    <AnalyticsData courseId={courseId} batchId={batchId} batchName={batchName} courseName={courseName} />
                </>
            }

        </>

    );

}

export default OverAllAnalytics;