import React, { useState } from 'react'
import userApi from 'src/utils/axios';
import { Formik } from "formik";
import * as yup from 'yup';
import ConfigData from './../../../../config/constant.json'
import { CCardBody, CCol, CRow, CModalHeader, CModal, CFormSelect, CInputGroup, CModalTitle, CModalBody, CForm, CFormInput, CFormCheck, } from '@coreui/react'
import Teacher from '../../Teacher/Teacher';
import { MultiSelect } from "react-multi-select-component";

const AddBatch = ({ visible, onClose, addToaster, addbatchCallback, courseId }) => {
    const [isChecked, setIsChecked] = useState(false);
    const [isFeatured, setIsFeatured] = useState(false);
    const [isInSelected, setInSelected] = useState('free');
    const [isOutSelected, setOutSelected] = useState('free');
    const [isTeachSelected, setTeachSelected] = useState('free');
    const course_id = courseId;
    const [imageError, setImageError] = useState(false)
    const [selectedFile, setSelectedFile] = useState(null);
    const [InHouse, setInHouse] = useState(false);
    const [weekDays, setWeekDays] = useState(false);
    const [time, setTime] = useState(false);
    const [OutSider, setOutSider] = useState(false);
    const [isTeacher, setIsTeacher] = useState(false);
    const [selected, setSelected] = useState([]);
    const [value, onChange] = useState('');

    const handleFileChange = (event) => {
        setSelectedFile(event.target.files[0]);
        setImageError(false);
    };

    const options = [
        { label: "Monday", value: "Monday" },
        { label: "Tuesday", value: "Tuesday" },
        { label: "Wednesday", value: "Wednesday" },
        { label: "Thursday", value: "Thursday" },
        { label: "Friday", value: "Friday" },
        { label: "Saturday", value: "Saturday" },
        { label: "Sunday", value: "Sunday" },
    ];

    const moduleSchema = yup.object({
        startDate: yup.date().required('Start Date is required'),
        endDate: yup.date().required('End Date is required'),
        registrationStartDate: yup.date().required('Registration Start Date is required'),
        registrationEndDate: yup.date().required('Registration  End Date is required'),
        batchName: yup.string().required('Batch Name  is required').min(3),
        mode: yup.string().required('Batch Mode is required'),
        inHouseFees: isInSelected === 'paid' ? yup.number().required('In House Fees is required') : yup.number(),
        outHouseFees: isOutSelected === 'paid' ? yup.number().required('In House Fees is required') : yup.number(),
        teacherFees: isTeachSelected === 'paid' ? yup.number().required('Teacher Fees is required') : yup.number(),
        weekOfDays: weekDays === true ? yup.array().required('Days are required') : yup.array(),
        weekOfTimeStart: time === true ? yup.string().required('Time are required') : yup.string(),
        weekOfTimeEnd: time === true ? yup.string().required('Time are required') : yup.string(),

    });

    const handleAddBatch = async (values, actions) => {
        try {
            if (selectedFile == null) {
                setImageError(true);
                return;
            }
            // Getting Body Data ready
            let bodyData = {
                ...values,
                inHousePayment: isInSelected,
                outHousePayment: isOutSelected,
                teacherPayment: isTeachSelected,
                courseId: course_id,
                isFeatured: isFeatured,
                isInHouse: InHouse,
                isOutSider: OutSider,
                isTeacher: isTeacher,
                isDays: weekDays,
                isTime: time
            }
            const formData = new FormData();
            formData.append('file', selectedFile);
            formData.append('values', JSON.stringify(bodyData));

            // Api Calling
            let response = await userApi.post(`${ConfigData.SERVER_URL}/admin/batch/addBatch`, formData)
            if (response.data.status == false) {
                throw Error(response.data.message);
            }

            let toast = {
                "status": true,
                "body": "Batch Added",
                "message": "Success"
            }

            addToaster(toast)
            addbatchCallback(response.data.batch)
            actions.resetForm();
            onClose();
        } catch (e) {

            let toast = {
                "status": false,
                "body": e.message,
                "message": "Failed"
            }

            addToaster(toast)
        }
    }

    const handleRadioChange = async (formikProps, value, type) => {

        if (type === 'inHouse') {
            if (type === 'inHouse') setInSelected(value)
            formikProps.setFieldValue('inHouseFees', '');
        }
        if (type === 'outHouse') {
            if (type === 'outHouse') setOutSelected(value)
            formikProps.setFieldValue('outHouseFees', '');
        }
        if (type === 'Teacher') {
            if (type === 'Teacher') setTeachSelected(value)
            formikProps.setFieldValue('teacherFees', '');
        }
    }


    const handleSwitchInput = async (formikProps, value, type) => {
        if (type === 'days') {
            setWeekDays(value);
            if (value === false) {
                formikProps.setFieldValue('weekOfDays', '');
            }
        }
        if (type === 'time') {
            setTime(value);
            if (value === false) {
                formikProps.setFieldValue('weekOfTimeStart', '');
                formikProps.setFieldValue('weekOfTimeEnd', '');
            }
        }
    }

    return (
        <>
            <CModal size="lg"
                scrollable visible={visible} alignment="center" onClose={onClose} backdrop='static'>
                <CModalHeader onClose={onClose}>
                    <CModalTitle>Add Batch</CModalTitle>
                </CModalHeader>
                <CModalBody>
                    <>
                        <CCardBody>
                            <Formik
                                initialValues={{ startDate: '', endDate: '', registrationStartDate: '', registrationEndDate: '', batchName: '', batchFees: '', mode: '', weekOfDays: [], weekOfTimeStart: '', weekOfTimeEnd: '' }}
                                validationSchema={moduleSchema}
                                onSubmit={(values, actions) => {
                                    handleAddBatch(values, actions);
                                }}
                            >
                                {(formikProps) => (
                                    <CForm className="row g-3 m-1">
                                        <CCol md={6}>
                                            <label className='text-sm'>Start Date :</label>
                                            <input type="date" className='form-control' label="Start Date" name="startDate" onChange={formikProps.handleChange('startDate')} value={formikProps.values.startDate} />
                                            <p style={{ color: 'red' }}>{formikProps.touched.startDate && formikProps.errors.startDate}</p>
                                        </CCol>

                                        <CCol md={6}>
                                            <label className='text-sm'>End Date :</label>
                                            <input type="date" className='form-control' name="endDate" onChange={formikProps.handleChange('endDate')} value={formikProps.values.endDate} />
                                            <p style={{ color: 'red' }}>{formikProps.touched.endDate && formikProps.errors.endDate}</p>
                                        </CCol>

                                        <CCol md={6}>
                                            <label className='text-sm'>Registration Start Date :</label>
                                            <input type="date" className='form-control' label="Start Date" name="startDate" onChange={formikProps.handleChange('registrationStartDate')} value={formikProps.values.registrationStartDate} />
                                            <p style={{ color: 'red' }}>{formikProps.touched.registrationStartDate && formikProps.errors.registrationStartDate}</p>
                                        </CCol>

                                        <CCol md={6}>
                                            <label className='text-sm'>Registration End Date :</label>
                                            <input type="date" className='form-control' name="endDate" onChange={formikProps.handleChange('registrationEndDate')} value={formikProps.values.registrationEndDate} />
                                            <p style={{ color: 'red' }}>{formikProps.touched.registrationEndDate && formikProps.errors.registrationEndDate}</p>
                                        </CCol>

                                        <CRow>
                                            <CCol md={6}>
                                                <label className='text-sm'>Do you want to set Day's?</label>
                                                <div className="form-check form-switch mt-3"  >
                                                    <input className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDefaultXl1" onChange={() => handleSwitchInput(formikProps, !weekDays, 'days')} />
                                                </div>
                                            </CCol>
                                            <CCol md={6}>
                                                {
                                                    weekDays === true &&
                                                    <div>
                                                        <label className='text-sm'>Set Day's</label>
                                                        <MultiSelect
                                                            options={options}
                                                            value={
                                                                options.filter(option => formikProps.values.weekOfDays.includes(option.value))
                                                            }
                                                            onChange={(selectedOptions) => {
                                                                const selectedValues = selectedOptions.map(option => option.value); // Get values
                                                                formikProps.setFieldValue('weekOfDays', selectedValues); // Set the field value
                                                            }}
                                                            labelledBy="Select"
                                                        />
                                                        <p style={{ color: 'red' }}>{formikProps.touched.weekOfDays && formikProps.errors.weekOfDays}</p>

                                                    </div>

                                                }
                                            </CCol>
                                        </CRow>
                                        <CRow>
                                            <CCol md={6}>
                                                <label className='text-sm'>Do you want to set Time?</label>
                                                <div className="form-check form-switch mt-3"  >
                                                    <input className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDefaultXl1" onChange={() => handleSwitchInput(formikProps, !time, 'time')} />
                                                </div>
                                            </CCol>
                                            <CCol md={6}>
                                                {
                                                    time === true &&
                                                    <div class="time-input-container">
                                                        <label for="time-input" class="time-label">Select Time:</label>
                                                        <div style={{ display: 'flex' }}>
                                                            <input id="time-input"
                                                                value={formikProps.values.weekOfTimeStart}
                                                                onChange={formikProps.handleChange('weekOfTimeStart')}
                                                                aria-label="Time" type="time" className="custom-time-input" />
                                                            <span style={{ margin: '4px' }}>to</span>
                                                            <input id="time-input"
                                                                value={formikProps.values.weekOfTimeEnd}
                                                                onChange={formikProps.handleChange('weekOfTimeEnd')}
                                                                aria-label="Time" type="time" className="custom-time-input" />
                                                        </div>
                                                        <p style={{ color: 'red' }}>{(formikProps.touched.weekOfTimeStart && formikProps.errors.weekOfTimeStart) || (formikProps.touched.weekOfTimeEnd && formikProps.errors.weekOfTimeEnd)}</p>
                                                    </div>
                                                }
                                            </CCol>
                                        </CRow>

                                        <CCol md={6}>
                                            <label className='text-sm'>Batch Name :</label>
                                            <CFormInput
                                                placeholder="Batch Name"
                                                autoComplete="batchName"
                                                onChange={formikProps.handleChange('batchName')}
                                                value={formikProps.values.batchName}
                                                onBlur={formikProps.handleBlur('batchName')}
                                            />
                                            <p style={{ color: 'red' }}>{formikProps.touched.batchName && formikProps.errors.batchName}</p>
                                        </CCol>

                                        <CCol md={6}>
                                            <label className='text-sm'>Mode of Batch :</label>
                                            <CFormSelect aria-label="Default select example"
                                                onChange={formikProps.handleChange('mode')}
                                                value={formikProps.values.mode}
                                            >
                                                <option disabled={true} selected value=''>Select mode</option>
                                                <option value="Online">Online</option>
                                                <option value="Offline">Offline</option>
                                                <option value="Hybrid" >Hybrid</option>
                                            </CFormSelect>
                                            <p style={{ color: 'red' }}>{formikProps.touched.mode && formikProps.errors.mode}</p>

                                        </CCol>

                                        <CRow>
                                            <CCol md={4}>
                                                <label className='text-sm'>Visible to InHouse  :</label>
                                                <div className="form-check form-switch mt-3"  >
                                                    <input className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDefaultXl1" onChange={() => setInHouse(!InHouse)} />
                                                </div>
                                            </CCol>
                                            <CCol md={4}>
                                                <label className='text-sm'>Visible to OutSider  :</label>
                                                <div className="form-check form-switch mt-3"  >
                                                    <input className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDefaultXl1" onChange={() => setOutSider(!OutSider)} />
                                                </div>
                                            </CCol>

                                            <CCol md={4}>
                                                <label className='text-sm'>Visible to Teacher :</label>
                                                <div className="form-check form-switch mt-3"  >
                                                    <input className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDefaultXl1" onChange={() => setIsTeacher(!isTeacher)} />
                                                </div>
                                            </CCol>
                                        </CRow>


                                        <CCol md={6}>
                                            {InHouse === true && <>
                                                <label className='text-sm'>InHouse Student Fees :</label>
                                                <CInputGroup className='gap-4 mt-3'>
                                                    <CFormCheck
                                                        type="radio"
                                                        id="isInSelected"
                                                        name="isInSelected"
                                                        label="Free"
                                                        value="free"
                                                        checked={isInSelected === 'free'}
                                                        onChange={() => handleRadioChange(formikProps, 'free', 'inHouse')}
                                                    />
                                                    <CFormCheck
                                                        type="radio"
                                                        id="isInSelected"
                                                        name="isInSelected"
                                                        label="Paid"
                                                        value="paid"
                                                        checked={isInSelected === 'paid'}
                                                        onChange={() => handleRadioChange(formikProps, 'paid', 'inHouse')}
                                                    />
                                                </CInputGroup>
                                            </>
                                            }

                                        </CCol>

                                        <CCol md={6}>
                                            {isInSelected === 'paid' && <>
                                                <label className='text-sm'>InHouse Student Amount :</label>
                                                <CFormInput
                                                    placeholder="Fees"
                                                    onChange={formikProps.handleChange('inHouseFees')}
                                                    value={formikProps.values.inHouseFees}
                                                    onBlur={formikProps.handleBlur('inHouseFees')}
                                                />
                                                <p style={{ color: 'red' }}>{formikProps.touched.inHouseFees && formikProps.errors.inHouseFees}</p>
                                            </>
                                            }
                                        </CCol>

                                        <CCol md={6}>
                                            {OutSider === true && <>
                                                <label className='text-sm'>Outer Student Fees :</label>
                                                <CInputGroup className='gap-4 mt-3'>
                                                    <CFormCheck
                                                        type="radio"
                                                        id="isOutSelected"
                                                        name="isOutSelected"
                                                        label="Free"
                                                        value="free"
                                                        checked={isOutSelected === 'free'}
                                                        onChange={() => handleRadioChange(formikProps, 'free', 'outHouse')}
                                                    />
                                                    <CFormCheck
                                                        type="radio"
                                                        id="isOutSelected"
                                                        name="isOutSelected"
                                                        label="Paid"
                                                        value="paid"
                                                        checked={isOutSelected === 'paid'}
                                                        onChange={() => handleRadioChange(formikProps, 'paid', 'outHouse')}
                                                    />
                                                </CInputGroup>
                                            </>
                                            }
                                        </CCol>

                                        <CCol md={6}>
                                            {isOutSelected === 'paid' && <>
                                                <label className='text-sm'>Outer Student Amount :</label>
                                                <CFormInput
                                                    placeholder="Fees"
                                                    onChange={formikProps.handleChange('outHouseFees')}
                                                    value={formikProps.values.outHouseFees}
                                                    onBlur={formikProps.handleBlur('outHouseFees')}
                                                />
                                                <p style={{ color: 'red' }}>{formikProps.touched.outHouseFees && formikProps.errors.outHouseFees}</p>
                                            </>
                                            }
                                        </CCol>

                                        <CCol md={6}>
                                            {isTeacher === true && <>
                                                <label className='text-sm'>Teacher Fees :</label>
                                                <CInputGroup className='gap-4 mt-3'>
                                                    <CFormCheck
                                                        type="radio"
                                                        id="isTeachSelected"
                                                        name="isTeachSelected"
                                                        label="Free"
                                                        value="free"
                                                        checked={isTeachSelected === 'free'}
                                                        onChange={() => handleRadioChange(formikProps, 'free', 'Teacher')}
                                                    />
                                                    <CFormCheck
                                                        type="radio"
                                                        id="isTeachSelected"
                                                        name="isTeachSelected"
                                                        label="Paid"
                                                        value="paid"
                                                        checked={isTeachSelected === 'paid'}
                                                        onChange={() => handleRadioChange(formikProps, 'paid', 'Teacher')}
                                                    />
                                                </CInputGroup>
                                            </>
                                            }
                                        </CCol>

                                        <CCol md={6}>
                                            {isTeachSelected === 'paid' && <>
                                                <label className='text-sm'>Teacher Amount :</label>
                                                <CFormInput
                                                    placeholder="Fees"
                                                    onChange={formikProps.handleChange('teacherFees')}
                                                    value={formikProps.values.teacherFees}
                                                    onBlur={formikProps.handleBlur('teacherFees')}
                                                />
                                                <p style={{ color: 'red' }}>{formikProps.touched.teacherFees && formikProps.errors.teacherFees}</p>
                                            </>
                                            }
                                        </CCol>
                                        <CCol md={6}>
                                            <label className='text-sm'>Upload :</label>
                                            <CFormInput
                                                type='file'
                                                onChange={handleFileChange}
                                            />
                                            {imageError && <div style={{ color: 'red' }}>Please select image</div>}
                                        </CCol>

                                        <CCol md={6}>
                                            <label className='text-sm'>Feature batch on home page?</label>
                                            <div className="form-check form-switch mt-3 " style={{ color: 'white' }} >
                                                <input className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDefaultXl1" onChange={() => setIsFeatured(!isFeatured)} />
                                            </div>
                                        </CCol>

                                        <CRow >
                                            <CCol xs={5}> </CCol>
                                            <CCol xs={2}>
                                                <button onClick={formikProps.handleSubmit} type="submit" className="btn btn-outline-primary mt-2">Submit</button> </CCol>
                                            <CCol xs={5}> </CCol>
                                        </CRow>
                                    </CForm>
                                )}
                            </Formik>
                        </CCardBody>
                    </>
                </CModalBody>
            </CModal>
        </>
    );
}

export default AddBatch;