import React, { useState, useEffect, useRef } from 'react';
import { CRow, CCol, CCard, CCardBody, CButton, CForm, CFormLabel, CCardTitle, CFormInput, CFormSelect, CAvatar, CModal, CModalHeader, CModalTitle, CModalBody, CModalFooter } from '@coreui/react/dist/index.js';
import { cilSearch } from '@coreui/icons';
import CIcon from '@coreui/icons-react';
import LoadingScreen from "src/views/shared/Loading";
import { Table } from 'react-bootstrap';
import ExcelFile from 'src/views/shared/ExcelFile';
import userApi from 'src/utils/axios';
import ConfigData from '../../../config/constant.json';
import AddQuiz from './AddQuiz';
import ReactPaginate from 'react-paginate';
import Question from './Question';
import Toaster from 'src/views/shared/Toaster';
import Multiselect from 'multiselect-react-dropdown';

const Quiz = () => {

    const [quiz, setQuiz] = useState(false);
    const [role, setRole] = useState(true);
    const [debouncedValue, setDebouncedValue] = useState('');
    let timeoutId; // Declare timeoutId variable outside of debounce function
    const itemsPerPage = 10;
    const [loader, setLoader] = useState(true);
    const [offset, setOffset] = useState(0);
    const [pageCount, setPageCount] = useState(0);
    const [count, setCount] = useState(0);
    const [quizData, setQuizData] = useState([]);
    const [deleteVisible, setDeleteVisible] = useState(false);
    const [updatedQuizId, setUpdatedQuizId] = useState('');
    const [visible, setVisible] = useState(false);
    const [viewQuestion, setViewQuestion] = useState(false);
    const [toast, addToast] = useState(false);
    const [filterBy, setFilterBy] = useState('');
    const [updatedStatus, setUpdatedStatus] = useState('');
    const [limitOfQuestion, setLimitOfQuestion] = useState('');
    const [updatedNoOfQuestion, setUpdatedNoOfQuestion] = useState(0);
    // const [updatedVideos, setUpdatedVideos] = useState([]);
    const [selectedVideos, setSelectedVideos] = useState([]);
    const [moduleVideoData, setModuleVideoData] = useState([]);

    const handleSelect = (selectedList) => {
        const videoIds = selectedList.map(item => item.videoId);
        setSelectedVideos(videoIds);
    };

    const handleRemove = (selectedList) => {
        const videoIds = selectedList.map(item => item.videoId);
        setSelectedVideos(videoIds);
    };
    useEffect(() => {
        if (!quiz) {
            if (debouncedValue) {
                fetchQuizData(debouncedValue);
            } else {
                fetchQuizData('');
            }
            const userRoles = localStorage.getItem("role");
            if (userRoles === 'VIEWER' || userRoles === 'TEACHER') {
                setRole(false);
            } else {
                setRole(true);
            }
        }
    }, [quiz, debouncedValue, filterBy, offset])

    // Handle search input change with debounce
    const handleSearch = (e) => {
        debounce(e.target.value, 500);
    }

    // Debounce function to delay search input
    const debounce = (value, delay) => {

        clearTimeout(timeoutId); // Clear the previous timeout if exists

        timeoutId = setTimeout(() => {
            setDebouncedValue(value);
        }, delay);

        // Cleanup function to clear the timeout on unmount or new debounce call
        return () => clearTimeout(timeoutId);
    }

    // Handle pagination click
    const handlePageClick = (e) => {
        setOffset(e.selected)
    }

    const fetchQuizData = async (searchTerm) => {
        try {
            setLoader(true);
            const response = await userApi.get(`${ConfigData.SERVER_URL}/admin/quiz/getQuiz?searchTerm=${searchTerm}&offset=${offset}&filterBy=${filterBy}`);
            if (response.data?.data) {
                setQuizData(response.data?.data?.quizData);
                setPageCount(Math.ceil(response.data?.data?.totalCount / itemsPerPage));
                setCount(response.data?.data?.totalCount)
            }
            setLoader(false); // Set loader to false after data fetching

        } catch (e) {
            // setError(`${e.message} :(`)
            setLoader(false) // Set loader to false on error
        }
    }

    const closeDeleteModel = async () => {
        setDeleteVisible(false);
    }

    const closeUpdateModal = async () => {
        setVisible(!visible);
    }

    const handleEditQuiz = async (quizData) => {
        setUpdatedQuizId(quizData._id);
        setUpdatedStatus(quizData.status);
        setUpdatedNoOfQuestion(quizData.noOfQuestion);
        fetchBatchData(quizData.batchId, quizData.courseId);
        setSelectedVideos(quizData.videoIds);
        setVisible(true);
    }

    const fetchBatchData = async (batchId, courseId) => {
        try {
            if (batchId && courseId) {
                const response = await userApi.get(`${ConfigData.SERVER_URL}/admin/batch/getModuleVideos?courseId=${courseId}&batchId=${batchId}`);
                // console.log('response', response);

                setModuleVideoData(response.data.data);
                // setSelectedBatch(batchId);
            }
        } catch (error) {
            console.error('Error fetching batch data:', error);
        }
    };

    const handleDeleteQuiz = async (quizId) => {
        setUpdatedQuizId(quizId);
        setDeleteVisible(true);
    }

    const handleViewQuestion = async (quizId, noOfQuestion) => {
        setLimitOfQuestion(noOfQuestion);
        setUpdatedQuizId(quizId);
        setViewQuestion(true);
    }

    const deleteQuizById = async () => {
        try {
            const response = await userApi.delete(`${ConfigData.SERVER_URL}/admin/quiz/deleteQuiz?quizId=${updatedQuizId}`);
            if (response.data.status == 200) {

                addToaster({ status: true, message: "Success", body: response.data.message });
                fetchQuizData(debouncedValue);
            } else {
                addToaster({ status: false, message: "Failure", body: response.data.message });
            }
        } catch (e) {
            console.error(e);
        } finally {
            setDeleteVisible(false);
        }
    }

    let addToaster = (toast) => {
        addToast(toast)
        setTimeout(() => {
            addToast(false)
        }, 3000)
    }

    // Handle filter by Status type change
    const filterByStatus = (e) => {
        setFilterBy(e.target.value);
    };


    const handleStatusChange = (event) => {
        setUpdatedStatus(event.target.value);
    };

    const handleInputChange = (e) => {
        setUpdatedNoOfQuestion(e.target.value);
    };

    const updateQuizData = async () => {
        if (updatedNoOfQuestion && updatedStatus && selectedVideos) {
            const response = await userApi.put(`${ConfigData.SERVER_URL}/admin/quiz/updateQuiz?quizId=${updatedQuizId}&status=${updatedStatus}&noOfQuestion=${updatedNoOfQuestion}&videoIds=${selectedVideos}`);
            if (response.data.status === true) {
                addToaster({ status: true, message: "Success", body: response.data.message });
                setVisible(false);
                fetchQuizData(debouncedValue);
            } else {
                addToaster({ status: false, message: "Failure", body: response.data.message });
            }
        }
    }

    return (
        <>{
            !viewQuestion &&
            <>
                <CRow>
                    <CCol sm={12} className='pb-4'>
                        <CCard style={{ background: "#fff" }}>
                            <CCardBody>
                                <CRow>
                                    {
                                        role &&
                                        <CCol sm={2} className="d-flex justify-content-start" >
                                            {quiz && <CButton style={{ backgroundColor: 'rgb(214, 178, 49)', color: 'black' }} onClick={() => setQuiz(false)}>Back</CButton>}
                                        </CCol>
                                    }
                                    <CCol sm={8} className="d-flex justify-content-center">
                                        <CCardTitle style={{ color: "#000" }}>{quiz ? 'Add Quiz' : 'Quiz'}</CCardTitle>
                                    </CCol>
                                    {role &&
                                        <CCol sm={2} className="d-flex justify-content-end" >
                                            {!quiz && <CButton style={{ backgroundColor: 'rgb(214, 178, 49)', color: 'black' }} onClick={() => setQuiz(true)}>Add Quiz</CButton>}
                                        </CCol>
                                    }
                                </CRow>
                            </CCardBody>
                        </CCard>
                    </CCol>
                </CRow>

                {
                    !quiz && !viewQuestion &&
                    < div >
                        {/* search and filter section */}
                        <CRow >
                            <CCol md={6} style={{ paddingLeft: '30px' }}>

                                <div style={{ position: 'relative', marginBottom: '1%', marginLeft: '2%' }}>
                                    <div style={{ position: 'absolute', top: '50%', left: '10px', transform: 'translateY(-50%)' }}>
                                        <CIcon icon={cilSearch} size="xl" />
                                    </div>

                                    <CFormInput
                                        placeholder="Search..."
                                        autoComplete="Search"
                                        style={{ paddingLeft: '40px' }} // Adjust paddingLeft to make space for the icon
                                        onChange={handleSearch}
                                    />
                                </div>
                            </CCol>
                            <CCol md={2}></CCol>
                            <CCol md={4}>
                                <CFormSelect aria-label="Default select example" onChange={filterByStatus}>
                                    <option disabled selected="true">Filter By Status</option>
                                    <option value="">All</option>
                                    <option value="ACTIVE">ACTIVE</option>
                                    <option value="INACTIVE">INACTIVE</option>
                                </CFormSelect>
                            </CCol>
                        </CRow>
                        {
                            !loader ?
                                <>
                                    <h5 style={{ marginBottom: '1%', marginLeft: '2%' }}>Quiz Count: <span ><b>{count}</b></span></h5>
                                    <div style={{ marginLeft: '2%', marginRight: '2%' }}>
                                        <Table striped bordered hover id='user-table'>
                                            <thead>
                                                <tr>
                                                    <th>Sr. No</th>
                                                    <th>Quiz Name</th>
                                                    <th>Course</th>
                                                    <th>Batch</th>
                                                    <th>Status</th>
                                                    <th>Limit of Questions</th>
                                                    <th>Action</th>
                                                    <th>Question</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {quizData.length > 0 &&
                                                    quizData.map((item, index) => (
                                                        <tr key={item._id}>
                                                            <td>{offset * 10 + (index + 1)}</td>
                                                            <td>{item?.quizName}</td>
                                                            <td>{item.course[0]?.courseName}</td>
                                                            <td>{item.batch[0]?.batchName}</td>
                                                            <td style={{ textAlign: 'center' }}>{item?.status === 'ACTIVE' ? <CAvatar color="success" style={{ width: '60px', padding: '2px' }} shape="rounded" textColor="white">ACTIVE</CAvatar>
                                                                : <CAvatar style={{ width: '60px', padding: '2px' }} color="info" shape="rounded" textColor="white">INACTIVE</CAvatar>}</td>
                                                            <td>{item?.noOfQuestion}</td>
                                                            <td style={{ textAlign: "center" }}>
                                                                <div>
                                                                    <CButton style={{ backgroundColor: '#14752a', color: 'white' }} onClick={() => handleEditQuiz(item)}>Update</CButton>
                                                                    <CButton style={{ marginLeft: '10px', backgroundColor: '#ab2f0c', color: 'white' }} onClick={() => handleDeleteQuiz(item._id)} >Delete</CButton>
                                                                </div></td>
                                                            <td>
                                                                <CButton style={{ backgroundColor: 'rgb(214, 178, 49)', color: 'black' }} onClick={() => handleViewQuestion(item._id, item.noOfQuestion)}>View</CButton>
                                                            </td>
                                                        </tr>
                                                    ))
                                                }
                                            </tbody>
                                        </Table>

                                        {/* Pagination Component */}
                                        <ReactPaginate
                                            previousLabel={"previous"}
                                            nextLabel={"next"}
                                            breakLabel={"..."}
                                            pageCount={pageCount}
                                            marginPagesDisplayed={2}
                                            pageRangeDisplayed={3}
                                            onPageChange={(event) => { handlePageClick(event) }}
                                            containerClassName={"pagination justify-content-center"}
                                            pageClassName={"page-item"}
                                            pageLinkClassName={"page-link"}
                                            previousClassName={"page-item"}
                                            previousLinkClassName={"page-link"}
                                            nextClassName={"page-item"}
                                            nextLinkClassName={"page-link"}
                                            breakClassName={"page-item"}
                                            breakLinkClassName={"page-link"}
                                            activeClassName={"active"}
                                            forcePage={offset}
                                        />
                                    </div>
                                </>
                                : <LoadingScreen />
                        }

                    </div >
                }


                {/* Update Modal  */}
                <CModal
                    backdrop="static"
                    visible={visible}
                    size='lg'
                    onClose={() => setVisible(false)}
                    alignment="center"
                    aria-labelledby="StaticBackdropExampleLabel"
                >
                    <CModalHeader>
                        <CModalTitle id="StaticBackdropExampleLabel">Update Quiz</CModalTitle>
                    </CModalHeader>
                    <CModalBody>
                        <CForm>
                            <CRow>
                                <CFormLabel htmlFor="videoIds"><b>Activate After Completing:</b> <span style={{ color: 'red' }}>*</span></CFormLabel>
                                <Multiselect
                                    displayValue="originalName"
                                    groupBy="module_name"
                                    options={moduleVideoData}
                                    showCheckbox
                                    selectedValues={moduleVideoData.filter(item => selectedVideos.includes(item.videoId))}
                                    onSelect={handleSelect}
                                    onRemove={handleRemove}
                                />
                            </CRow>
                            <CRow>
                                <CCol>
                                    <CFormLabel htmlFor="autoSizingSelect">Select Status</CFormLabel>
                                    <CFormSelect id="autoSizingSelect" value={updatedStatus} onChange={handleStatusChange}>
                                        <option value="ACTIVE">ACTIVE</option>
                                        <option value="INACTIVE">INACTIVE</option>
                                    </CFormSelect>
                                </CCol>
                                <CCol>
                                    <CFormLabel htmlFor="endDatePicker">No. of Questions:</CFormLabel>
                                    <CFormInput
                                        id="endDatePicker"
                                        type="number"
                                        aria-describedby="exampleFormControlInputHelpInline"
                                        value={updatedNoOfQuestion}
                                        onChange={handleInputChange}
                                    />
                                </CCol>
                            </CRow>

                        </CForm>
                    </CModalBody>
                    <CModalFooter>
                        <CButton color="secondary" onClick={closeUpdateModal}>
                            Close
                        </CButton>
                        <CButton color="success" onClick={updateQuizData}>Update Quiz</CButton>
                    </CModalFooter>
                </CModal>


                {/* Delete Modal */}
                <CModal
                    visible={deleteVisible}
                    backdrop="static"
                    onClose={closeDeleteModel}
                    alignment="center"
                    aria-labelledby="StaticBackdropExampleLabel"
                >
                    <CModalHeader>
                        <CModalTitle id="StaticBackdropExampleLabel">Delete Confirmation</CModalTitle>
                    </CModalHeader>
                    <CModalBody>
                        <span style={{ fontSize: '20px', fontWeight: 'bold' }}>Are you sure you want to delete this Quiz?</span>
                    </CModalBody>
                    <CModalFooter>
                        <CButton style={{ backgroundColor: '#14752a', color: 'white' }} onClick={deleteQuizById}>Yes</CButton>
                        <CButton style={{ backgroundColor: '#ab2f0c', color: 'white' }} onClick={closeDeleteModel}>
                            No
                        </CButton>
                    </CModalFooter>
                </CModal>

                {
                    quiz &&
                    <AddQuiz></AddQuiz>
                }

                {
                    toast && <Toaster message={toast.message} status={toast.status} body={toast.body} ></Toaster>
                }
            </>
        }
            {
                viewQuestion &&
                <Question quizId={updatedQuizId} limitOfQuestion={limitOfQuestion}></Question>
            }

        </>
    )
}

export default Quiz;