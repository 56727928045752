import React, { useRef, useState, useEffect } from 'react'
import { useHistory } from "react-router-dom";
import userApi from 'src/utils/axios';
import { Formik } from "formik";
import * as yup from 'yup';
import ConfigData from './../../../../config/constant.json'
import { CCardBody, CCol, CFormSelect, CRow, CModalHeader, CFormCheck, CModal, CInputGroup, CModalTitle, CModalBody, CForm, CFormInput, } from '@coreui/react'
import moment from 'moment'
import { MultiSelect } from "react-multi-select-component";

const EditBatch = ({ visible, onClose, addToaster, editbatchCallback, courseId, batch }) => {
    const [isChecked, setIsChecked] = useState(batch.lockContent);
    const [isFeatured, setIsFeatured] = useState(batch.isFeatured);
    const [isInSelected, setInSelected] = useState(batch.inHousePayment);
    const [isOutSelected, setOutSelected] = useState(batch.outHousePayment);
    const [isTeachSelected, setTeachSelected] = useState(batch.teacherPayment);

    const course_id = courseId;
    const [imageError, setImageError] = useState(false)
    const [selectedFile, setSelectedFile] = useState(null);
    const [InHouse, setInHouse] = useState(batch.isInHouse);
    const [OutSider, setOutSider] = useState(batch.isOutSider);
    const [isTeacher, setIsTeacher] = useState(batch.isTeacher);
    const [weekDays, setWeekDays] = useState(batch.isDays);
    const [time, setTime] = useState(batch.isTime);

    const options = [
        { label: "Monday", value: "Monday" },
        { label: "Tuesday", value: "Tuesday" },
        { label: "Wednesday", value: "Wednesday" },
        { label: "Thursday", value: "Thursday" },
        { label: "Friday", value: "Friday" },
        { label: "Saturday", value: "Saturday" },
        { label: "Sunday", value: "Sunday" },
    ];
    let url = `${ConfigData.SERVER_URL}/batchFiles/${batch.fileName}.${batch.extension}`;


    const BatchSchema = yup.object({
        startDate: yup.date().required('Start Date is required'),
        endDate: yup.date().required('End Date is required'),
        registrationStartDate: yup.date().required('Registration Start Date is required'),
        registrationEndDate: yup.date().required('Registration  End Date is required'),
        batchName: yup.string().required('Batch Name  is required').min(3),
        mode: yup.string().required('Batch Mode is required'),
        inHouseFees: isInSelected === 'paid' ? yup.number().required('In House Fees is required') : yup.number(),
        outHouseFees: isOutSelected === 'paid' ? yup.number().required('In House Fees is required') : yup.number(),
        teacherFees: isTeachSelected === 'paid' ? yup.number().required('Teacher Fees is required') : yup.number(),
        weekOfDays: weekDays === true ? yup.array().required('Days are required') : yup.array(),
        weekOfTimeStart: time === true ? yup.string().required('Time are required') : yup.string(),
        weekOfTimeEnd: time === true ? yup.string().required('Time are required') : yup.string(),
    });

    const handleFileChange = (event) => {
        setSelectedFile(event.target.files[0]);
        setImageError(false);
    };

    const handleEditBatch = async (values) => {
        try {
            // Getting Body Data ready
            let bodyData = {
                ...values,
                inHousePayment: isInSelected,
                outHousePayment: isOutSelected,
                teacherPayment: isTeachSelected,
                courseId: course_id,
                isFeatured: isFeatured,
                isInHouse: InHouse,
                isOutSider: OutSider,
                isTeacher: isTeacher,
                isDays: weekDays,
                isTime: time
            }
            const formData = new FormData();
            formData.append('file', selectedFile);
            formData.append('id', batch._id);
            formData.append('values', JSON.stringify(bodyData));

            let response = await userApi.post(`${ConfigData.SERVER_URL}/admin/batch/editBatch`, formData)
            if (response.data.status == false) {
                throw Error(response.data.message);
            }
            let toast = {
                "status": true,
                "body": "Batch Updated",
                "message": "Success"
            }

            addToaster(toast)
            editbatchCallback({
                ...values,
                _id: batch._id,
                inHousePayment: isInSelected,
                outHousePayment: isOutSelected,
                teacherPayment: isTeachSelected,
                courseId: courseId,
                isPublished: batch.isPublished,
                lockContent: isChecked == true ? true : false,
                lockContentAfter: isChecked == true ? values.lockContentAfter : 10,
                isFeatured: isFeatured,
                isInHouse: InHouse,
                isOutSider: OutSider,
                isTeacher: isTeacher,
                isDays: weekDays,
                isTime: time
            })

        } catch (e) {

            let toast = {
                "status": false,
                "body": "Batch Not Updated",
                "message": "Failed"
            }

            addToaster(toast)
        }
    }

    const lockContent = async (value, id) => {
        try {
            let response = await userApi.post(`${ConfigData.SERVER_URL}/admin/batch/lockContent`, { isLocked: value, id: id })
            if (!response.data.status == true) {
                throw Error("Something went wrong");
            }
            batch.lockContent = !value;
            setIsChecked(!value)
        } catch (e) {
            console.log(e);
        }
    }

    const initialValues = {
        startDate: batch.startDate,
        endDate: batch.endDate,
        registrationStartDate: batch.registrationStartDate,
        registrationEndDate: batch.registrationEndDate,
        batchName: batch.batchName,
        outHouseFees: batch.outHouseFees,
        inHouseFees: batch.inHouseFees,
        teacherFees: batch.teacherFees,
        isInSelected: batch.isInSelected,
        isOutSelected: batch.isOutSelected,
        isTeachSelected: batch.isTeachSelected,
        mode: batch.mode,
        fileName: batch.fileName,
        extension: batch.extension,
        InHouse: batch.isInHouse,
        OutSider: batch.isOutSider,
        isTeacher: batch.isTeacher,
        isDays: batch.isDays,
        isTime: batch.isTime,
        weekOfDays: batch.weekOfDays,
        weekOfTimeStart: batch.weekOfTimeStart,
        weekOfTimeEnd: batch.weekOfTimeEnd
    }

    const handleRadioChange = async (formikProps, value, type) => {
        if (type === 'inHouse') {
            if (type === 'inHouse') setInSelected(value)
            formikProps.setFieldValue('inHouseFees', '');
        }
        if (type === 'outHouse') {
            if (type === 'outHouse') setOutSelected(value)
            formikProps.setFieldValue('outHouseFees', '');
        }
        if (type === 'Teacher') {
            if (type === 'Teacher') setTeachSelected(value)
            formikProps.setFieldValue('teacherFees', '');
        }
    }

    const handleSwitchInput = async (formikProps, value, type) => {
        if (type === 'days') {
            setWeekDays(value);
            if (value === false) {
                formikProps.setFieldValue('weekOfDays', '');
            }
        }
        if (type === 'time') {
            setTime(value);
            if (value === false) {
                formikProps.setFieldValue('weekOfTimeStart', '');
                formikProps.setFieldValue('weekOfTimeEnd', '');
            }
        }
    }

    return (
        <>
            <CModal size="lg" scrollable visible={visible} alignment="center" onClose={onClose} backdrop='static'>
                <CModalHeader onClose={onClose}>
                    <CModalTitle>Edit Batch</CModalTitle>
                </CModalHeader>
                <CModalBody>
                    <>
                        <CCardBody>
                            <Formik
                                initialValues={initialValues}
                                validationSchema={BatchSchema}
                                onSubmit={(values, actions) => {
                                    handleEditBatch(values);
                                    actions.resetForm();
                                    onClose();
                                }}
                            >
                                {(formikProps) => (
                                    <CForm className="row g-3 m-1">
                                        <CCol md={6}>
                                            <label className='text-sm'>Start date :<b>{moment(formikProps.values.startDate).format('DD-MMM-YYYY')}</b></label>
                                            <input className='form-control' type="date" name="startDate" onChange={formikProps.handleChange('startDate')} />
                                            <p style={{ color: 'red' }}>{formikProps.touched.startDate && formikProps.errors.startDate}</p>
                                        </CCol>

                                        <CCol md={6}>
                                            <label className='text-sm'>End Date :<b>{moment(formikProps.values.endDate).format('DD-MMM-YYYY')}</b></label>
                                            <input className='form-control' type="date" name="endDate" onChange={formikProps.handleChange('endDate')} />
                                            <p style={{ color: 'red' }}>{formikProps.touched.endDate && formikProps.errors.endDate}</p>
                                        </CCol>

                                        <CCol md={6}>
                                            <label className='text-sm'>Registration Start date :<b>{formikProps.values.registrationStartDate ? moment(formikProps.values.registrationStartDate).format('DD-MMM-YYYY') : ''}</b></label>
                                            <input className='form-control' type="date" name="registrationStartDate" onChange={formikProps.handleChange('registrationStartDate')} />
                                            <p style={{ color: 'red' }}>{formikProps.touched.registrationStartDate && formikProps.errors.registrationStartDate}</p>
                                        </CCol>

                                        <CCol md={6}>
                                            <label className='text-sm'>Registration End Date :<b>{formikProps.values.registrationEndDate ? moment(formikProps.values.registrationEndDate).format('DD-MMM-YYYY') : ''}</b></label>
                                            <input className='form-control' type="date" name="registrationEndDate" onChange={formikProps.handleChange('registrationEndDate')} />
                                            <p style={{ color: 'red' }}>{formikProps.touched.registrationEndDate && formikProps.errors.registrationEndDate}</p>
                                        </CCol>

                                        <CRow>
                                            <CCol md={6}>
                                                <label className='text-sm'>Do you want to set Day's?</label>
                                                <div className="form-check form-switch mt-3"  >
                                                    <input className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDefaultXl1"
                                                        onChange={() => handleSwitchInput(formikProps, !weekDays, 'days')} checked={weekDays} />
                                                </div>
                                            </CCol>
                                            <CCol md={6}>
                                                {
                                                    weekDays === true &&
                                                    <div>
                                                        <label className='text-sm'>Set Day's</label>
                                                        <MultiSelect
                                                            options={options}
                                                            value={
                                                                options.filter(option => formikProps.values.weekOfDays.includes(option.value))
                                                            }
                                                            onChange={(selectedOptions) => {
                                                                const selectedValues = selectedOptions.map(option => option.value); // Get values
                                                                formikProps.setFieldValue('weekOfDays', selectedValues); // Set the field value
                                                            }}
                                                            labelledBy="Select"
                                                        />
                                                        <p style={{ color: 'red' }}>{formikProps.touched.weekOfDays && formikProps.errors.weekOfDays}</p>

                                                    </div>
                                                }
                                            </CCol>
                                        </CRow>
                                        <CRow>
                                            <CCol md={6}>
                                                <label className='text-sm'>Do you want to set Time?</label>
                                                <div className="form-check form-switch mt-3"  >
                                                    <input className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDefaultXl1" onChange={() => handleSwitchInput(formikProps, !time, 'time')} checked={time} />
                                                </div>
                                            </CCol>
                                            <CCol md={6}>
                                                {
                                                    time === true &&
                                                    <div className="time-input-container">
                                                        <label for="time-input" className="time-label">Select Time:</label>
                                                        <div style={{ display: 'flex' }}>
                                                            <input id="time-input"
                                                                value={formikProps.values.weekOfTimeStart}
                                                                onChange={formikProps.handleChange('weekOfTimeStart')}
                                                                aria-label="Time" type="time" className="custom-time-input" />
                                                            <span style={{ margin: '4px' }}>to</span>
                                                            <input id="time-input"
                                                                value={formikProps.values.weekOfTimeEnd}
                                                                onChange={formikProps.handleChange('weekOfTimeEnd')}
                                                                aria-label="Time" type="time" className="custom-time-input" />
                                                        </div>
                                                        <p style={{ color: 'red' }}>{(formikProps.touched.weekOfTimeStart && formikProps.errors.weekOfTimeStart) || (formikProps.touched.weekOfTimeEnd && formikProps.errors.weekOfTimeEnd)}</p>
                                                    </div>
                                                }
                                            </CCol>
                                        </CRow>

                                        <CCol md={6}>
                                            <label className='text-sm'>Batch Name</label>
                                            <CFormInput
                                                placeholder="Batch Name"
                                                autoComplete="batchName"
                                                onChange={formikProps.handleChange('batchName')}
                                                value={formikProps.values.batchName}
                                                onBlur={formikProps.handleBlur('batchName')}
                                            />
                                            <p style={{ color: 'red' }}>{formikProps.touched.batchName && formikProps.errors.batchName}</p>
                                        </CCol>
                                        <CCol md={6}>
                                            <label className='text-sm'>Mode of Batch :</label>
                                            <CFormSelect aria-label="Default select example"
                                                onChange={formikProps.handleChange('mode')}
                                                value={formikProps.values.mode}
                                            >
                                                <option disabled={true} selected value=''>Select mode</option>
                                                <option value="Online">Online</option>
                                                <option value="Offline">Offline</option>
                                                <option value="Hybrid" >Hybrid</option>
                                            </CFormSelect>
                                            <p style={{ color: 'red' }}>{formikProps.touched.mode && formikProps.errors.mode}</p>

                                        </CCol>


                                        <CCol md={4}>
                                            <label className='text-sm'>Visible to InHouse  :</label>
                                            <div className="form-check form-switch mt-3"  >
                                                <input className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDefaultXl1" onChange={() => setInHouse(!InHouse)} checked={InHouse} />
                                            </div>
                                        </CCol>
                                        <CCol md={4}>
                                            <label className='text-sm'>Visible to OutSider  :</label>
                                            <div className="form-check form-switch mt-3"  >
                                                <input className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDefaultXl1" onChange={() => setOutSider(!OutSider)} checked={OutSider} />
                                            </div>
                                        </CCol>

                                        <CCol md={4}>
                                            <label className='text-sm'>Visible to Teacher  :</label>
                                            <div className="form-check form-switch mt-3"  >
                                                <input className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDefaultXl1" onChange={() => setIsTeacher(!isTeacher)} checked={isTeacher} />
                                            </div>
                                        </CCol>

                                        <CCol md={6}>
                                            {InHouse === true && <>
                                                <label className='text-sm'>InHouse Student Fees :</label>
                                                <CInputGroup className='gap-4 mt-3'>
                                                    <CFormCheck
                                                        type="radio"
                                                        id="isInSelected"
                                                        name="isInSelected"
                                                        label="Free"
                                                        value="free"
                                                        checked={isInSelected === 'free'}
                                                        onChange={() => handleRadioChange(formikProps, 'free', 'inHouse')}
                                                    />
                                                    <CFormCheck
                                                        type="radio"
                                                        id="isInSelected"
                                                        name="isInSelected"
                                                        label="Paid"
                                                        value="paid"
                                                        checked={isInSelected === 'paid'}
                                                        onChange={() => handleRadioChange(formikProps, 'paid', 'inHouse')}
                                                    />
                                                </CInputGroup>
                                            </>
                                            }
                                        </CCol>

                                        <CCol md={6}>
                                            {isInSelected === 'paid' && <>
                                                <label className='text-sm'>InHouse Student Amount :</label>
                                                <CFormInput
                                                    placeholder="Fees"
                                                    onChange={formikProps.handleChange('inHouseFees')}
                                                    value={formikProps.values.inHouseFees}
                                                    onBlur={formikProps.handleBlur('inHouseFees')}
                                                />
                                                <p style={{ color: 'red' }}>{formikProps.touched.inHouseFees && formikProps.errors.inHouseFees}</p>
                                            </>
                                            }
                                        </CCol>

                                        <CCol md={6}>
                                            {OutSider === true && <>
                                                <label className='text-sm'>Outer Student Fees :</label>
                                                <CInputGroup className='gap-4 mt-3'>
                                                    <CFormCheck
                                                        type="radio"
                                                        id="isOutSelected"
                                                        name="isOutSelected"
                                                        label="Free"
                                                        value="free"
                                                        checked={isOutSelected === 'free'}
                                                        onChange={() => handleRadioChange(formikProps, 'free', 'outHouse')}
                                                    />
                                                    <CFormCheck
                                                        type="radio"
                                                        id="isOutSelected"
                                                        name="isOutSelected"
                                                        label="Paid"
                                                        value="paid"
                                                        checked={isOutSelected === 'paid'}
                                                        onChange={() => handleRadioChange(formikProps, 'paid', 'outHouse')}
                                                    />
                                                </CInputGroup>
                                            </>
                                            }
                                        </CCol>

                                        <CCol md={6}>
                                            {isOutSelected === 'paid' && <>
                                                <label className='text-sm'>Outer Student Amount :</label>
                                                <CFormInput
                                                    placeholder="Fees"
                                                    onChange={formikProps.handleChange('outHouseFees')}
                                                    value={formikProps.values.outHouseFees}
                                                    onBlur={formikProps.handleBlur('outHouseFees')}
                                                />
                                                <p style={{ color: 'red' }}>{formikProps.touched.outHouseFees && formikProps.errors.outHouseFees}</p>
                                            </>
                                            }
                                        </CCol>

                                        <CCol md={6}>
                                            {isTeacher === true && <>
                                                <label className='text-sm'>Teacher Fees :</label>
                                                <CInputGroup className='gap-4 mt-3'>
                                                    <CFormCheck
                                                        type="radio"
                                                        id="isTeachSelected"
                                                        name="isTeachSelected"
                                                        label="Free"
                                                        value="free"
                                                        checked={isTeachSelected === 'free'}
                                                        onChange={() => handleRadioChange(formikProps, 'free', 'Teacher')}
                                                    />
                                                    <CFormCheck
                                                        type="radio"
                                                        id="isTeachSelected"
                                                        name="isTeachSelected"
                                                        label="Paid"
                                                        value="paid"
                                                        checked={isTeachSelected === 'paid'}
                                                        onChange={() => handleRadioChange(formikProps, 'paid', 'Teacher')}
                                                    />
                                                </CInputGroup>
                                            </>
                                            }
                                        </CCol>

                                        <CCol md={6}>
                                            {isTeachSelected === 'paid' && <>
                                                <label className='text-sm'>Teacher Amount :</label>
                                                <CFormInput
                                                    placeholder="Fees"
                                                    onChange={formikProps.handleChange('teacherFees')}
                                                    value={formikProps.values.teacherFees}
                                                    onBlur={formikProps.handleBlur('teacherFees')}
                                                />
                                                <p style={{ color: 'red' }}>{formikProps.touched.teacherFees && formikProps.errors.teacherFees}</p>
                                            </>
                                            }
                                        </CCol>

                                        <CCol md={4}>
                                            <label className='text-sm'>Change Upload :</label>
                                            <CFormInput
                                                type='file'
                                                onChange={handleFileChange}
                                            />
                                            {imageError && <div style={{ color: 'red' }}>Please select image</div>}
                                        </CCol>
                                        <CCol md={2} >
                                            {batch.fileName ? (
                                                <div className="flex justify-center mt-4 pt-2 ml-2">
                                                    <a href={url} style={{ color: 'black', textDecoration: 'none', backgroundColor: 'green', borderRadius: '5px', padding: '6px' }} target="-blank" rel="noopener noreferrer">
                                                        <span >View File</span>
                                                    </a>
                                                </div>
                                            ) : null}
                                        </CCol>

                                        <CCol sm={6}>
                                            <label className='text-sm'>Feature batch on home page?</label>
                                            <div className="form-check form-switch " style={{ color: 'white' }} >
                                                <input className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDefaultXl1" onChange={() => setIsFeatured(!isFeatured)} checked={isFeatured} />
                                            </div>
                                        </CCol>

                                        <CRow >
                                            <CCol xs={5}> </CCol>
                                            <CCol xs={2}>
                                                <button onClick={formikProps.handleSubmit} type="submit" className="btn btn-outline-primary">Submit</button> </CCol>
                                            <CCol xs={5}> </CCol>
                                        </CRow>
                                    </CForm>
                                )}
                            </Formik>
                        </CCardBody>
                    </>
                </CModalBody>
            </CModal>
        </>
    );
}

export default EditBatch;