import {
    CCard,
    CCardBody,
    CForm,
    CFormInput,
    CFormLabel,
    CRow,
    CCol,
    CFormCheck,
    CButton
} from '@coreui/react';
import React, { useState } from 'react';
import { Formik } from "formik";
import * as Yup from 'yup';
import { cilLibraryAdd, cilTrash } from '@coreui/icons';
import CIcon from '@coreui/icons-react';
import userApi from 'src/utils/axios';
import ConfigData from 'src/config/constant.json';
import Toaster from 'src/views/shared/Toaster';

const AddQuestion = ({ quizId, initialData, closeModal }) => {
    const initializeOptions = (options) =>
        options.map((option, index) => ({
            id: option.id || Date.now() + index, // Generate a unique ID if none exists
            value: option.answer,
            isCorrect: option.isCorrect,
        }));
    const [options, setOptions] = useState(initialData ? initializeOptions(initialData.options) : [{ id: Date.now(), value: '', isCorrect: false }]);

    const [toast, addToast] = useState(false);

    const handleAddOption = () => {
        const newOption = { id: Date.now(), value: '', isCorrect: false };
        setOptions([...options, newOption]);
    };

    const handleRemoveOption = (id) => {
        setOptions(options.filter((option) => option.id !== id));
    };

    const handleOptionChange = (id, value) => {
        setOptions(options.map((option) => (option.id === id ? { ...option, value } : option)));
    };

    const handleCorrectChange = (id, isCorrect) => {
        // Set only the selected option as correct
        setOptions(options.map((option) => {
            if (option.id === id) {
                return { ...option, isCorrect }; // Toggle the isCorrect value for the selected option
            }
            return { ...option, isCorrect: false }; // Unset correct for all other options
        }));
    };

    const handleIncorrectChange = (id) => {
        // Mark the option as incorrect by unsetting isCorrect
        setOptions(options.map((option) => (
            option.id === id ? { ...option, isCorrect: false } : option
        )));
    };

    const handleSave = async (values, { resetForm }) => {
        const formattedOptions = options.map(({ value, isCorrect }) => ({ answer: value, isCorrect }));
        const data = { question: values.question, options: formattedOptions, quizId: quizId, questionId: initialData?._id };
        const response = await userApi.post(`${ConfigData.SERVER_URL}/admin/quiz/addQuestion`, data);

        if (response.data.status === true) {
            addToaster({ status: true, message: "Success", body: response.data.message });
            resetForm();
            setOptions([{ id: Date.now(), value: '', isCorrect: false }]); // Reset options state
            closeModal(); // Close modal after successful save
        } else {
            addToaster({ status: false, message: "Failure", body: response.data.message });
        }
    };

    const addToaster = (toast) => {
        addToast(toast)
        setTimeout(() => {
            addToast(false)
        }, 3000)
    }

    // Validation schema
    const validationSchema = Yup.object().shape({
        question: Yup.string().required('Question is required'),
    });

    return (
        <>
            <CCard>
                <CCardBody>
                    <Formik
                        initialValues={{ question: initialData?.question || '' }}
                        validationSchema={validationSchema}
                        onSubmit={handleSave}
                    >
                        {({ values, handleChange, handleBlur, handleSubmit, errors }) => {
                            const isSaveDisabled = !values.question ||
                                options.length < 2 ||
                                options.some(option => option.value === '') || // Disable if any option is empty
                                options.every(option => !option.isCorrect); // Disable if no option is marked as correct

                            return (
                                <CForm onSubmit={handleSubmit} style={{ padding: '10px' }}>
                                    <CRow className="mb-3">
                                        <CFormLabel htmlFor="question">
                                            <b>Question:</b> <span style={{ color: 'red' }}>*</span>
                                        </CFormLabel>
                                        <CFormInput
                                            type="text"
                                            name="question"
                                            id="question"
                                            placeholder="Enter Question"
                                            value={values.question}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                        />
                                        {errors.question && <div style={{ color: 'red' }}>{errors.question}</div>}
                                    </CRow>

                                    {options.map((option, index) => (
                                        <CRow key={option.id} className="mb-3 align-items-center">
                                            <CCol md={4}>
                                                <CFormLabel htmlFor={`option-${option.id}`}>
                                                    <b>Option {index + 1}:</b> <span style={{ color: 'red' }}>*</span>
                                                </CFormLabel>
                                                <CFormInput
                                                    type="text"
                                                    id={`option-${option.id}`}
                                                    value={option.value}
                                                    onChange={(e) => handleOptionChange(option.id, e.target.value)}
                                                />
                                            </CCol>

                                            <CCol md={6} style={{ display: 'flex', alignItems: 'center', gap: '20px', paddingTop: '10px' }}>
                                                <CFormCheck
                                                    type="checkbox"
                                                    checked={option.isCorrect} // Check if this option is correct
                                                    onChange={() => handleCorrectChange(option.id, true)} // Set this option as correct
                                                    label="Correct"
                                                    style={{ marginRight: '10px' }} // Add space
                                                />
                                                <CFormCheck
                                                    type="checkbox"
                                                    checked={!option.isCorrect} // Treat unmarked as incorrect
                                                    onChange={() => handleIncorrectChange(option.id)} // Set this option as incorrect
                                                    label="Incorrect"
                                                />
                                            </CCol>

                                            <CCol md={2}>
                                                {index === 0 ? (
                                                    <CIcon icon={cilLibraryAdd} size="xl" onClick={handleAddOption} style={{ cursor: 'pointer' }} />
                                                ) : (
                                                    <CIcon icon={cilTrash} size="xl" onClick={() => handleRemoveOption(option.id)} style={{ cursor: 'pointer' }} />
                                                )}
                                            </CCol>
                                        </CRow>
                                    ))}
                                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                        <CButton color="primary" type="submit" disabled={isSaveDisabled}>{initialData ? 'Update Question' : 'Save Question'}</CButton>
                                    </div>
                                </CForm>
                            );
                        }}
                    </Formik>
                </CCardBody>
            </CCard>
            {
                toast && <Toaster message={toast.message} status={toast.status} body={toast.body} ></Toaster>
            }
        </>
    );
};

export default AddQuestion;
