import React, { useEffect, useState } from "react"
import { CRow, CCol, CCard, CCardBody, CButton, CProgress, CProgressBar, CCardTitle, CAvatar, CFormInput, CFormSelect, CModal, CModalHeader, CModalTitle, CModalBody, CModalFooter, CAccordion, CAccordionItem, CAccordionBody, CAccordionHeader } from '@coreui/react/dist/index.js';
import AnalyticsData from "./AnalyticsData";
import userApi from 'src/utils/axios';
import ConfigData from '../../../config/constant.json';
import { Table } from 'react-bootstrap';
import menIcon from '../../../assets/images/profileMen.png';
import womenIcon from '../../../assets/images/profileWomen.png'
import DOMPurify from 'dompurify';

const VideoAnalyticsData = ({ courseId, batchId, courseName, batchName, userId }) => {

    const [backView, setBackView] = useState(false);
    const [usersData, setUsersData] = useState([]);
    const [moduleData, setModuleData] = useState([]);
    const [status, setStatus] = useState('');
    const [totalPercentage, setTotalPercentage] = useState(0);
    const [videoStatus, setVideoStatus] = useState([]);

    useEffect(() => {
        getAnalyticsData();
    }, []);

    const getAnalyticsData = async () => {
        const response = await userApi.get(`${ConfigData.SERVER_URL}/batches/getVideoAnalyticsData?batchId=${batchId}&courseId=${courseId}&userId=${userId}`);

        if (response) {
            setUsersData(response.data?.user);
            setModuleData(response.data?.data?.moduleData);

            setStatus(response.data?.message);
            setTotalPercentage(response.data?.totalPercentage);
            setVideoStatus(response.data?.percentageArray);
        }
    }

    const goBack = () => {
        setBackView(true);
    }


    return (
        <>{
            !backView && <>

                <CRow>
                    <CCol sm={12} className='pb-4'>
                        <CCard style={{ background: "#fff" }}>
                            <CCardBody>
                                <CRow>
                                    <CCol sm={1}>
                                        <CButton style={{ backgroundColor: 'rgb(214, 178, 49)', color: 'black' }} onClick={() => goBack()}>Back</CButton>
                                    </CCol>
                                    <CCol sm={3}>
                                    </CCol>
                                    <CCol sm={8}>
                                        <CCardTitle style={{ color: "#000" }}>{`${courseName} (${batchName})`}</CCardTitle>
                                    </CCol>
                                </CRow>
                            </CCardBody>
                        </CCard>
                    </CCol>
                </CRow>

                <CRow>
                    <CCol md={4}>
                        <CCard style={{ padding: '15px', display: 'flex', alignItems: 'center', gap: '20px' }}>
                            {/* Profile Image */}

                            <div>
                                <img
                                    src={`${usersData[0]?.fileName
                                        ? `${ConfigData.SERVER_URL}/ProfileImages/${usersData[0].fileName}.${usersData[0].extension}`
                                        : (usersData[0]?.gender === 'male' || usersData[0]?.gender === 'Male' || usersData[0]?.gender === 'MALE')
                                            ? menIcon
                                            : womenIcon}`}
                                    alt="Profile"
                                    style={{
                                        width: '100px',
                                        height: '100px',
                                        border: '1px solid',
                                        borderRadius: '50%',  // Makes the image rounded
                                        objectFit: 'cover'    // Ensures the image covers the entire area without distortion
                                    }}
                                />
                            </div>

                            {/* Student Information */}
                            <div style={{ flex: 1 }}>
                                <span><b>Name:</b> {usersData[0]?.fullName ? usersData[0]?.fullName : (usersData[0]?.firstName ? `${usersData[0]?.firstName} ${usersData[0]?.lastName}` : usersData[0]?.name)}</span><br />
                                <span><b>Email:</b> {usersData[0]?.email}</span><br />
                                <span><b>Student Email:</b> {usersData[0]?.studentEmail || '-NA-'}</span><br />
                                <span><b>Role:</b> {usersData[0]?.roleType}</span><br />
                                {
                                    usersData[0]?.roleType === 'STUDENT' && (
                                        <span><b>Student Type:</b> {usersData[0]?.registration === false ? 'INHOUSE' : 'OUTSIDER'}</span>
                                    )
                                }<br />
                                <span><b>Mobile No:</b> {usersData[0]?.mobile || usersData[0]?.number}</span>
                            </div>
                        </CCard>

                        <CCard style={{ padding: '10px', marginTop: '20px' }}>
                            <div style={{ fontWeight: 'bold', display: 'flex', alignItems: 'center', justifyContent: 'center', fontSize: '20px', paddingBottom: '10px' }}>Progress Status</div>
                            <CProgress color="success" value={totalPercentage}>
                                <CProgressBar>{`${Math.round(totalPercentage)}%`}</CProgressBar>
                            </CProgress>
                        </CCard>

                    </CCol>
                    <CCol md={8}>
                        <CAccordion alwaysOpen activeItemKey={1}>
                            {moduleData.map((mod, i) => (
                                <CAccordionItem itemKey={i + 1} key={i}>
                                    <CAccordionHeader>{mod.module_name}</CAccordionHeader>
                                    <CAccordionBody>
                                        {
                                            mod?.videos.length > 0 ?
                                                <Table striped bordered hover id='user-table'>
                                                    <thead>
                                                        <tr>
                                                            <th>Sr. No</th>
                                                            <th>Video Name</th>
                                                            <th>Total Duration</th>
                                                            <th>Played Duration</th>
                                                            <th>Percent</th>
                                                            <th>Status</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {mod?.videos.length > 0 &&
                                                            // mod?.videos.map((item, index) => (
                                                            //     <tr key={item._id}>
                                                            //         <td>{index + 1}</td>
                                                            //         <td>{item.title}</td>
                                                            //         <td>{item.duration}</td>
                                                            //         {
                                                            //             videoStatus ? videoStatus.length > 0 &&
                                                            //                 videoStatus.filter(data => data.videoId === item._id)
                                                            //                     .map((data, index) => (
                                                            //                         <>
                                                            //                             <td>
                                                            //                                 <span>{data.playedDuration}</span>
                                                            //                             </td>
                                                            //                             <td>
                                                            //                                 <span key={index}>{data.percentagePlayed}%</span>
                                                            //                             </td>
                                                            //                             <td>
                                                            //                                 <span key={index}>{data.percentagePlayed > 98.00 ? <CAvatar style={{ width: '100px', padding: '2px' }} color="success" shape="rounded" textColor="white">COMPLETE</CAvatar> : <CAvatar style={{ width: '100px', padding: '2px' }} color="danger" shape="rounded" textColor="white">INCOMPLETE</CAvatar>}</span>

                                                            //                             </td>
                                                            //                         </>
                                                            //                     )) :
                                                            //                 <>
                                                            //                     <td>00:00:00</td>
                                                            //                     <td>00.00%</td>
                                                            //                     <td><CAvatar style={{ width: '100px', padding: '2px' }} color="danger" shape="rounded" textColor="white">INCOMPLETE</CAvatar></td>
                                                            //                 </>
                                                            //         } </tr>
                                                            // ))
                                                            mod?.videos.map((item, index) => {
                                                                const currentVideoStatus = videoStatus?.filter(data => data.videoId === item._id);

                                                                return (
                                                                    <tr key={item._id}>
                                                                        <td>{index + 1}</td>
                                                                        <td>{item.title}</td>
                                                                        <td>{item.duration}</td>

                                                                        {currentVideoStatus && currentVideoStatus.length > 0 ? (
                                                                            currentVideoStatus.map((data, idx) => (
                                                                                <React.Fragment key={idx}>
                                                                                    <td>
                                                                                        <span>{data.playedDuration || "00:00:00"}</span>
                                                                                    </td>
                                                                                    <td>
                                                                                        <span>{data.percentagePlayed || "00.00"}%</span>
                                                                                    </td>
                                                                                    <td>
                                                                                        <span>
                                                                                            {data.percentagePlayed > 98.00 ? (
                                                                                                <CAvatar
                                                                                                    style={{ width: '100px', padding: '2px' }}
                                                                                                    color="success"
                                                                                                    shape="rounded"
                                                                                                    textColor="white"
                                                                                                >
                                                                                                    COMPLETE
                                                                                                </CAvatar>
                                                                                            ) : (
                                                                                                <CAvatar
                                                                                                    style={{ width: '100px', padding: '2px' }}
                                                                                                    color="danger"
                                                                                                    shape="rounded"
                                                                                                    textColor="white"
                                                                                                >
                                                                                                    INCOMPLETE
                                                                                                </CAvatar>
                                                                                            )}
                                                                                        </span>
                                                                                    </td>
                                                                                </React.Fragment>
                                                                            ))
                                                                        ) : (
                                                                            // Fallback for missing video status data
                                                                            <>
                                                                                <td>00:00:00</td>
                                                                                <td>00.00%</td>
                                                                                <td>
                                                                                    <CAvatar
                                                                                        style={{ width: '100px', padding: '2px' }}
                                                                                        color="danger"
                                                                                        shape="rounded"
                                                                                        textColor="white"
                                                                                    >
                                                                                        INCOMPLETE
                                                                                    </CAvatar>
                                                                                </td>
                                                                            </>
                                                                        )}
                                                                    </tr>
                                                                );
                                                            })

                                                        }
                                                    </tbody>
                                                </Table>
                                                : (
                                                    <div>No Data Found.</div>
                                                )}
                                    </CAccordionBody>
                                </CAccordionItem>
                            ))}
                        </CAccordion>

                        {
                            moduleData.length === 0 &&
                            <span style={{
                                display: 'block',
                                fontSize: '16px',
                                fontWeight: 'bold',
                                color: '#ff6b6b',
                                backgroundColor: '#f8d7da',
                                padding: '10px',
                                borderRadius: '5px',
                                textAlign: 'center',
                                marginTop: '10px'
                            }}>No modules found</span>
                        }
                    </CCol>
                </CRow>
            </>
        }
            {
                backView && <>
                    <AnalyticsData courseId={courseId} batchId={batchId} batchName={batchName} courseName={courseName} />
                </>
            }
        </>
    )
};

export default VideoAnalyticsData;